.table__draggable-headers {
    width: 100%;
    height: 30px;

    background-color: rgba(128, 128, 128, 0.2);
    display: flex;
    align-items: center;

    padding-left: 100px;
}

.table__draggable-headers__text {
    font-family: "Roboto";
    font-size: 12px;
    color: #1d2934;
}

.draggable-header {
    height: 14px;
    padding-left: 10px;
    border-radius: 7px;
    margin-left: 7px;

    background-color: var(--color-over-menu-bar);

    font-size: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.draggable-header__button {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-left: 14px;
    margin-right: 4px;

    background-color: var(--color-climate-green);

    font-size: 10px;
    color: #fff;
}

@media screen and (min-width: 1440px) {
    .table__draggable-headers__text {
        font-size: 13px;
    }

    .table__draggable-headers {
        height: 35px;
    }
}

@media screen and (min-width: 1920px) {
    .table__draggable-headers__text {
        font-size: 14px;
    }

    .table__draggable-headers {
        height: 40px;
    }
}

@media screen and (min-width: 2300px) {
    .table__draggable-headers__text {
        font-size: 18px;
    }

    .table__draggable-headers {
        height: 60px;
    }
}
