.mobile-header {
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(168.41deg, #f7fafe 0.43%, #eff4fa 105.08%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobile-header__navbar {
    z-index: 999;
    position: fixed;
    height: 100%;
}

.mobile-header__icon {
    margin: auto 0;
    height: 25px;
    width: 25px;
}
