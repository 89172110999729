.data-source-drop {
    margin: 10px 40px 10px -50px;
}

.data-source-drop:hover {
    cursor: pointer;
}

.data-source-drop__selection {
    display: flex;
    justify-content: space-between;
    height: 75%;
    width: 140px;
    font-family: "Roboto";
    background-color: #dee0e3;
    border-bottom: 1px solid #505050;
    border-radius: 3px 3px 0 0;
}

.data-source-drop__text-container {
    margin-left: 10px;
}

.data-source-drop__title {
    font-size: 10px;
    color: #1c9690;
}

.data-source-drop__selected {
    overflow: hidden;
    height: 14px;
    font-size: 12px;
    color: #505050;
}

.data-source-drop__arrow-icon {
    padding-left: 5px;
    margin-right: 2px;
    margin-top: 4px;
}

.data-source-drop__arrow-icon--open {
    padding-left: 5px;
    margin-right: 6px;
    margin-top: 4px;
    transform: rotate(90deg);
}

.data-source-drop__options-container {
    display: none;
}

.data-source-drop__options-container--open {
    z-index: 999;
    display: initial;
    position: absolute;
}

.data-source-drop__option {
    padding: 5px 10px;
    height: 25px;
    width: 140px;
    font-size: 11px;
    color: #505050;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.data-source-drop__option.selected {
    background: rgb(247 247 247);
    color: rgba(0, 0, 0, 0.95);
    font-weight: 500;
}

.data-source-drop__option.suggested::after {
    content: "Best";
    font-size: 9px;
    font-style: italic;
    color: #219992;
}

.data-source-drop__option:hover {
    background-color: #dee0e3;
}
