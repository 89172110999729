.planting-tool {
    padding-left: 2em;
    padding-bottom: 2em;
    padding-top: 1em;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.planting-tool .MuiCard-root{
    background:#FFFFFF  !important;
}

.cl-button {
    color: rgb(28, 150, 144) !important;
}

.cl-button {
    background: #f3f7fb !important;
    box-shadow: 0px 4px 10px #c0d2e4;
    border: 1px solid #1c9690 !important;
    color: #1c9690;
    border-radius: 11px !important;
    text-transform: capitalize !important;
    padding: 3px 15px !important;
    letter-spacing: initial !important;
}

.cl-button:disabled {
    color: rgb(29 41 52 / 50%) !important;
    border: 1px solid rgb(29 41 52 / 50%) !important;
    cursor: not-allowed !important;
}

.planting-tool__error-message {
    color: red;
    font-family: "Montserrat";
    font-size: 11px;
}

.d3-tip {
    z-index: 99999;
}

.chart-preload-container.with-message {
    flex-direction: column;
}

.chart-preload-container.with-message .message {
    margin-top: 12px;
}

.planting-tool__date-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    /* background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%); */
}

.planting-tool_date-option {
    margin: 5px;
}

.planting-tool__risk-details-item {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.planting-tool__risk-factors__title {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
}

.planting-tool__risk-factors__text {
    margin-top: -5px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
}

.planting-tool__chart .svg-chart-container {
    background: transparent !important;
}

@media screen and (max-width: 1100px) {
    .planting-tool {
        padding-right: 70px;
    }
    .planting-tool__date-container {
        justify-content: space-between;
        margin: 0 30px;
    }
    .planting-tool__risk-details-item {
        padding: 15px;
    }
}
