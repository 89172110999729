.navbar-fields {
    box-sizing: border-box;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    background-color: white;
}

.navbar-fields--highlight {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    background-color: #dde8f3;
}

.navbar-fields__menu {
    margin-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-fields__menu__return-button {
    margin-top: 1px;
    margin-left: 15%;
}

.navbar-fields__menu__return-button:hover {
    cursor: pointer;
}

.navbar-fields__menu__text {
    margin-left: 15%;
    flex-grow: 2;
    display: table-cell;
    vertical-align: middle;
    line-height: 200%;
    font-family: "Roboto";
    font-size: 11px;
    font-weight: 700;
}

.navbar-fields__menu__deploy-button {
    margin-top: 5px;
    margin-right: 7%;
    height: 13px;
    width: 13px;
}

.navbar-fields__menu__deploy-button.rotate {
    transform: rotate(90deg);
}

.navbar-fields__menu__deploy-button:hover {
    cursor: pointer;
}

.navbar-fields__menu__deployable-fields {
    display: none;
}

.navbar-fields__menu__deployable-fields--deployed {
    position: relative;
    display: block;
    height: max-content;
    width: min-content;
    background-color: #dde8f3;
    padding-bottom: 10%;
}

.navbar-fields__search-bar {
    margin: auto;
    margin-bottom: 4%;
    height: 25px;
    width: 90%;
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 0.2px solid rgba(29, 41, 52, 0.2);
}

.navbar-fields__search-bar:focus-within {
    border: 0.2px solid var(--color-dark-blue);
}

.navbar-fields__search-bar__icon {
    margin-top: -6px;
    margin-left: 3%;
    width: 34px;
    height: 34px;
}

.navbar-fields__search-bar__input {
    margin-left: 3%;
    width: 108px;
    height: 100%;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 300;
    border: none;

    outline: none;
}

/* The break in the height probably has a relationship with the margin-bottom */
.navbar-fields__fields-list {
    margin: auto;
    margin-top: 8%;
}

.navbar-fields__fields-list__info {
    max-height: 190px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #93c5e3 #c5dff0;
}

.navbar-fields__fields-list__region {
    margin-left: 6%;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: #1d2934;
    padding-bottom: 5px;
}

.navbar-fields__fields-list__items-container {
    margin-bottom: 6px;
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar-fields__menu {
        height: 40px;
    }
    .navbar-fields__menu__return-button {
        margin-top: 5px;
        height: 24px;
        width: 24px;
    }

    .navbar-fields__menu__text {
        margin-top: 2px;
        margin-left: 24px;
        font-size: 16px;
    }

    .navbar-fields__menu__deploy-button {
        margin-top: 8px;
        height: 20px;
    }

    .navbar-fields__search-bar {
        height: 40px;
    }

    .navbar-fields__search-bar__icon {
        width: 42px;
        height: 42px;
    }

    .navbar-fields__search-bar__input {
        margin-left: 10px;
        padding-bottom: 6px;
        font-size: 16px;
    }

    .navbar-fields__fields-list__region {
        font-size: 14px;
    }
} */

/* @media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar-fields__menu {
        height: 50px;
    }
    .navbar-fields__menu__return-button {
        margin-top: 7px;
        height: 30px;
        width: 30px;
    }

    .navbar-fields__menu__text {
        margin-top: 4px;
        margin-left: 30px;
        font-size: 20px;
    }

    .navbar-fields__menu__deploy-button {
        margin-top: 11px;
        margin-right: 20px;
        height: 28px;
    }

    .navbar-fields__menu__deployable-fields--deployed {
        width: 200px;
    }

    .navbar-fields__search-bar {
        height: 40px;
    }

    .navbar-fields__search-bar__icon {
        margin-top: 4px;
        width: 26px;
        height: 26px;
    }

    .navbar-fields__search-bar__input {
        margin-left: 12px;
        padding-bottom: 2px;
        font-size: 19px;
    }

    .navbar-fields__fields-list__region {
        font-size: 16px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar-fields {
        margin-top: 30px;
        margin-left: 100px;
        background-color: transparent;
        border-bottom: none;
    }

    .navbar-fields--highlight {
        margin-top: 30px;
    }

    .navbar-fields--highlight .navbar-fields__menu {
        width: 300px;
    }

    .navbar-fields--highlight .navbar-fields__menu__text {
        padding-left: 100px;
        width: 100px;
    }

    .navbar-fields__menu {
        width: 200px;
    }

    .navbar-fields__menu__return-button {
        display: none;
    }

    .navbar-fields__menu__text {
        margin: 0;
        font-size: 22px;
    }

    .navbar-fields__menu__deploy-button {
        margin: auto 0;
        height: 24px;
        width: 24px;
    }

    .navbar-fields__menu__deployable-fields--deployed {
        margin-left: 90px;
        width: 280px;
    }

    .navbar-fields__search-bar {
        height: 35px;
    }

    .navbar-fields__search-bar__icon {
        margin: auto 0 auto 8px;
        width: 21px;
        height: 21px;
    }

    .navbar-fields__search-bar__input {
        font-family: "Montserrat";
        font-size: 18px;
    }

    .navbar-fields__fields-list__region {
        font-size: 22px;
        font-weight: 500;
    }
}
