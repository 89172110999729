.decision-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.decision-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 35px;
    padding-right: 35px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: max-content;
    height: max-content;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.decision-modal__title {
    font-size: 15px;
    font-weight: 700;
    color: #1d2934;
}

.decision-modal__text {
    margin-top: 15px;

    width: 350px;

    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
    opacity: 0.5;
}

.decision-modal__button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: 10px;
}

.decision-modal__decision-button {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;

    width: 150px;
    height: 20px;

    font-family: "Montserrat";
    line-height: 1.7;
    font-size: 12px;
    font-weight: 500;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.decision-modal__decision-button:hover {
    cursor: pointer;
}

.decision-modal__decision-button:disabled {
    background-color: #e3aa80;
}

.decision-modal__decision-button:disabled:hover {
    cursor: not-allowed;
}

.decision-modal__cancel-button {
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 18px;

    font-family: "Montserrat";
    line-height: 1.6;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: rgba(0, 0, 0, 0.51);
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.decision-modal__cancel-button:hover {
    cursor: pointer;
}
