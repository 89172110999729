.crop-table {
    width: "100%";

    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 40px;
}

.crop-table__container {
    width: 900px;
}

.crop-table__icon-button {
    width: 19px;
    height: 19px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.crop-table__toggler-button {
    margin-bottom: 3px !important;
    margin-left: 20px !important;
    border: none;
    background: none;
    outline: none;

    cursor: pointer;
}

.crop-table__toggler-icon {
    width: 5px;
    height: 10px;
}

.crop-table__toggler-icon--open {
    width: 5px;
    height: 10px;
    transform: rotate(90deg);
}

.crop-table__icon-button:hover {
    background-color: #ddd;
}

.crop-table__edit-icon {
    width: 16px;
    height: 18px;

    padding: 2px;
}

.crop-table__trash-icon {
    height: 22px;
    width: 22px;

    padding: 2px;
}

.crop-table__check-icon {
    height: 18px;
    width: 18px;

    padding: 1px;
}

.crop-table__alert-icon {
    height: 22px;
    width: 22px;
}

.crop-table__warning-icon {
    height: 18px;
    width: 18px;
}

.green-pct {
    color: green;
}

.red-pct {
    color: red;
}

@media screen and (max-width: 1000px) {
    .crop-table__container {
        padding: 0 30px;
        width: 100%;
    }
}

@media screen and (min-width: 1440px) {
    .crop-table__container {
        width: 1100px;
    }

    .crop-table__toggler-button {
        margin-bottom: 0 !important;
    }

    .crop-table__toggler-icon {
        width: 6px;
        height: 12px;
    }

    .crop-table__toggler-icon--open {
        width: 6px;
        height: 12px;
    }

    .crop-table__edit-icon {
        width: 16px;
        height: 17px;
    }

    .crop-table__trash-icon {
        height: 24px;
        width: 24px;
    }

    .crop-table__alert-icon {
        height: 24px;
        width: 24px;
    }
}

@media screen and (min-width: 1920px) {
    .crop-table__container {
        width: 1350px;
    }

    .crop-table__toggler-icon {
        width: 8px;
        height: 14px;
    }

    .crop-table__toggler-icon--open {
        width: 8px;
        height: 14px;
    }

    .crop-table__edit-icon {
        width: 18px;
        height: 18px;
    }

    .crop-table__trash-icon {
        height: 28px;
        width: 28px;
    }

    .crop-table__alert-icon {
        height: 28px;
        width: 28px;
    }
}

@media screen and (min-width: 2300px) {
    .crop-table__container {
        width: 1600px;
    }

    .crop-table__toggler-icon {
        width: 10px;
        height: 16px;
    }

    .crop-table__toggler-icon--open {
        width: 10px;
        height: 16px;
    }

    .crop-table__icon-button {
        height: 32px;
        width: 32px;
    }

    .crop-table__edit-icon {
        width: 29px;
        height: 29px;
    }

    .crop-table__trash-icon {
        height: 38px;
        width: 38px;
    }

    .crop-table__alert-icon {
        width: 34px;
        height: 34px;
    }
}
