@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap);
.copyright {
    font-family: "Montserrat";
    font-size: 12px;
    color: white;
}

.copyright-text {
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 1000px) {
    .copyright {
        font-size: 16px;
    }
}

.sign-up {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.sign-up__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.sign-up__logo {
    padding-bottom: 40px;
    -webkit-align-self: center;
            align-self: center;
    width: 230px;
    height: 70px;
}

.sign-up__content {
    margin-bottom: 10%;
    width: 491px;
    height: 378px;
    -webkit-align-self: center;
            align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.sign-up__title {
    margin-top: 50px;
    margin-left: 18%;
    width: 315px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.sign-up__checkbox-container {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.sign-up__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 19%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: -webkit-max-content;
    max-width: max-content;
    max-height: 11.5px;
}

.sign-up__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.sign-up__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: white;
    border: 1px solid #505050;
}

.sign-up__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.sign-up__checkmark--checked {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #1d2934;
    border: 1px solid #505050;
}

.sign-up__checkmark--checked::after {
    content: "";
    position: absolute;
    left: 2px;
    bottom: 0.5px;
    display: block;
    width: 3.5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sign-up__checkbox-text {
    position: absolute;
    margin-left: 15px;
    margin-top: 1px;
    width: -webkit-max-content;
    width: max-content;
    font-size: 14px;
    font-weight: 600;
    color: #505050;
}

.sign-up__form {
    margin-top: 20px;
    margin-left: 25%;
}

.sign-up__text-input {
    margin-top: 20px;
    padding-left: 12px;
    padding-bottom: 2px;
    width: 65%;
    font-size: 12px;
    line-height: 1.6;
    color: #505050;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.sign-up__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 7%;
    gap: 7%;
}

.sign-up__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.sign-up__cancel-button:hover {
    cursor: pointer;
}

.sign-up__confirm-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #7e8382a8;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;

    cursor: not-allowed !important;
}

.sign-up__confirm-button.enabled {
    background-color: #1f9992;
    cursor: pointer !important;
}

.sign-up__confirm-button:hover {
    cursor: pointer;
}

.sign-up__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .sign-up__content {
        margin-bottom: 15%;
    }
}

.sign-up-confirmation {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.sign-up-confirmation__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.sign-up-confirmation__logo {
    padding-bottom: 40px;
    -webkit-align-self: center;
            align-self: center;
    width: 230px;
    height: 70px;
}

.sign-up-confirmation__content {
    margin-bottom: 14%;
    width: 491px;
    height: 250px;
    -webkit-align-self: center;
            align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.sign-up-confirmation__title-container {
    margin-top: 60px;
    margin-left: 10%;
}

.sign-up-confirmation__title {
    width: 400px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.sign-up-confirmation__text {
    margin-left: 10%;
    margin-top: 15px;
    width: 400px;
    font-size: 12px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.sign-up-confirmation__button {
    margin-left: 40%;
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.sign-up-confirmation__button:hover {
    cursor: pointer;
}

.sign-up-confirmation__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .sign-up-confirmation__content {
        margin-bottom: 15%;
    }
}

.restore-password {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.restore-password__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.restore-password__logo {
    padding-bottom: 40px;
    -webkit-align-self: center;
            align-self: center;
    width: 230px;
    height: 70px;
}

.restore-password__content {
    margin-bottom: 10%;
    width: 380px;
    height: 290px;
    -webkit-align-self: center;
            align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.restore-password__title {
    margin-top: 50px;
    margin-left: 8%;
    width: 315px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.restore-password__text {
    margin-left: 22%;
    margin-top: 15px;
    width: 200px;
    font-size: 12px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.restore-password__form {
    margin-top: 30px;
    margin-left: 17%;
}

.restore-password__text-input {
    margin-left: 5%;
    padding-bottom: 2px;
    width: 65%;
    font-size: 12px;
    line-height: 1.6;
    color: #505050;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.restore-password__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 7%;
    gap: 7%;
}

.restore-password__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.restore-password__cancel-button:hover {
    cursor: pointer;
}

.restore-password__confirm-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.restore-password__confirm-button:hover {
    cursor: pointer;
}

.restore-password__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .restore-password__content {
        margin-bottom: 15%;
    }
}

.password-code {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.password-code__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.password-code__logo {
    padding-bottom: 40px;
    -webkit-align-self: center;
            align-self: center;
    width: 230px;
    height: 70px;
}

.password-code__content {
    margin-bottom: 10%;
    /* justify-content: center; */
    width: 380px;
    height: 290px;
    -webkit-align-self: center;
            align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.password-code__title {
    margin-top: 50px;
    margin-left: 8%;
    width: 315px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.password-code__text {
    margin-left: 13%;
    margin-top: 15px;
    width: 280px;
    font-size: 12px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.password-code__form {
    margin-top: 30px;
    margin-left: 16%;
}

.password-code__text-input {
    margin-left: 2%;
    padding-bottom: 2px;
    width: 75%;
    font-size: 12px;
    line-height: 1.6;
    color: #505050;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.password-code__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 7%;
    gap: 7%;
}

.password-code__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.password-code__cancel-button:hover {
    cursor: pointer;
}

.password-code__confirm-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.password-code__confirm-button:hover {
    cursor: pointer;
}

.password-code__resend-code {
    margin-top: 20px;
    margin-left: 40%;
    font-size: 12px;
    color: #1e72c1;
}

.password-code__resend-code:hover {
    text-decoration: underline;
    cursor: pointer;
}

.password-code__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .password-code__content {
        margin-bottom: 15%;
    }
}

.create-password {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.create-password__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.create-password__logo {
    padding-bottom: 40px;
    -webkit-align-self: center;
            align-self: center;
    width: 230px;
    height: 70px;
}

.create-password__content {
    margin-bottom: 10%;
    /* justify-content: center; */
    width: 380px;
    height: 340px;
    -webkit-align-self: center;
            align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.create-password__title {
    margin-top: 35px;
    margin-left: 8%;
    width: 315px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.create-password__text {
    margin-left: 18%;
    margin-top: 15px;
    width: 230px;
    font-size: 12px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.create-password__form {
    margin-top: 20px;
    margin-left: 16%;
}

.create-password__form-text {
    margin-left: 25%;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

.create-password__form-text-2 {
    margin-top: 10px;
    margin-left: 15%;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

.create-password__text-input {
    margin-left: 2%;
    padding-bottom: 2px;
    width: 75%;
    font-size: 12px;
    line-height: 1.6;
    color: #505050;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.create-password__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 7%;
    gap: 7%;
}

.create-password__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.create-password__cancel-button:hover {
    cursor: pointer;
}

.create-password__confirm-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.create-password__confirm-button:hover {
    cursor: pointer;
}

.create-password__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .create-password__content {
        margin-bottom: 15%;
    }
}

.password-confirmation {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.password-confirmation__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.password-confirmation__logo {
    padding-bottom: 40px;
    -webkit-align-self: center;
            align-self: center;
    width: 230px;
    height: 70px;
}

.password-confirmation__content {
    margin-bottom: 14%;
    width: 380px;
    height: 200px;
    -webkit-align-self: center;
            align-self: center;
    border: 1px solid #f47920;
    border-radius: 5px;
}

.password-confirmation__title {
    margin-top: 60px;
    margin-left: 10%;
    width: 300px;
    font-size: 20px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: center;
}

.password-confirmation__button {
    margin-left: 26%;
    margin-top: 20px;
    margin-bottom: 7px;

    width: 180px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.password-confirmation__button:hover {
    cursor: pointer;
}

.password-confirmation__copyright {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 48px;
    font-size: 10px;
    color: white;
    background-color: #1d2934;
}

@media (min-height: 1080px) {
    .password-confirmation__content {
        margin-bottom: 15%;
    }
}

/* Table styles */

.table,
.table * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.table {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.table__container {
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-generic);
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    border-radius: 20px;
    /* overflow: hidden; */
}

.table__main-header {
    width: 100%;
    padding: 10px;
    position: relative;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.table__main-header__title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.table__main-header__title__controls-left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 20px;
}
.table__main-header__title__controls-right {
    padding-left: 20px;
    border-left: 1px solid #ddd;

    position: absolute;

    left: 90px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.table__main-header__title__button-container {
    width: 40px;
}

.table__main-header__title__button {
    width: 26px;
    height: 26px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    cursor: pointer;

    transition: all 0.2s ease-out;
}
.table__main-header__title__button > div {
    width: 100%;
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.table__main-header__title__button:hover {
    background-color: #ddd;
}

.table__main-header__title__checkbox-container {
    width: 60px;
    padding-right: 25px;

    position: absolute;
    left: 40px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    /* padding-left: 15px; */
}

.table__main-header__title__controls-right__label-button {
    padding-right: 22px;
}

.table__main-header__title__select {
    display: block;
    border: none;
    background: #dee0e3;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;

    font-size: 10px;
    font-weight: 500;

    border-bottom: 2px solid #666;
    border-top-left-radius: 2px;
    border-top-right-radius: 4px;
    cursor: pointer;
}

.table__main-header__title__text {
    padding-left: 28px;

    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat";
}

.table__main-header__search-bar {
    width: 230px;

    margin-right: 40px;
    border-bottom: 1px solid #000;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.table__main-header__search-bar__input {
    padding-left: 8px;
    padding-right: 8px;
    border: none;
    outline: none;
    background: none;

    font-size: 12px;

    -webkit-flex: 1 1;

            flex: 1 1;
}

.table__main-header__search-bar__button {
    width: 26px;
    height: 26px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    color: var(--color-climate-green);

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table__main-header__search-bar__button:hover {
    background-color: #ddd;
}

/* TableHeader Component */
.table-header .table-header__icon {
    position: absolute;
    right: 0;
    font-size: 12px;
    padding: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    opacity: 0;
}

.table-header.active {
    color: #333;
}

.table-header:hover .table-header__icon {
    opacity: 1;
}

.table-header.active .table-header__icon {
    opacity: 1;
}

/* table pagination */
.table__pagination {
    width: 50%;
    padding-top: 20px;
    padding-right: 20px;

    -webkit-align-self: flex-end;

            align-self: flex-end;
}

/* table alert */
.alert-dot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: #ffbd3d;
}

/* table shape file */
.shape-file {
    width: 16px;
    height: 16px;
    border-radius: 8px;

    background: #22c11f;

    font-size: 14px;
    color: #fff;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

/* table weather file */
.weather-file {
    font-size: 18px;
    color: #ffbd3d;
}

.table__loader {
    width: 100%;
    height: 2px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.table__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 2px;
    background-color: var(--color-climate-green);

    -webkit-animation: table__loader--animation 1s ease-out infinite;

            animation: table__loader--animation 1s ease-out infinite;
}

.table__mapbox__container {
    max-height: 1000px;
    overflow: auto;
    transition: max-height 0.25s ease-out;
}

.table__mapbox__container.hide {
    max-height: 0;
}

@-webkit-keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@media screen and (min-width: 1440px) {
    .table__main-header {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .table__main-header__search-bar {
        width: 280px;
    }

    .table__main-header__search-bar__input {
        font-size: 13px;
    }
    .table__main-header__title__button {
        width: 28px;
        height: 28px;
    }
}

@media screen and (min-width: 1920px) {
    .table__main-header__search-bar {
        width: 300px;
    }

    .table__main-header__search-bar__input {
        font-size: 14px;
    }

    .table__main-header__title__button {
        width: 30px;
        height: 30px;
    }
}

@media screen and (min-width: 2300px) {
    .table__main-header {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .table__main-header__search-bar {
        width: 400px;
    }

    .table__main-header__search-bar__input {
        font-size: 18px;
    }

    .table__main-header__title__button {
        height: 40px;
        width: 40px;
    }
}

/* table content */

.table__content {
    border-collapse: collapse;
    width: 100%;
}

.table__content * {
    font-family: "Roboto";
}

.table__content .table-item,
.table__content tr {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    /* height: 30px; */
}

.table__content thead {
    background-color: #1f9992;
}

.table__content tbody tr:nth-child(2n + 1) {
    background-color: none;
}

.table__content tbody tr:nth-child(2n) {
    background-color: rgba(231, 232, 235, 0.5);
}

.table__content tbody .table-item .toggler {
    cursor: pointer;
}

.table__content tr {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.table__content tr th {
    font-weight: 700;
}

.table__content tr td {
    font-weight: 400;
}

.table__content tr th,
.table__content tr td {
    position: relative;

    /* padding-left: 10px; */

    font-size: 12px;
    height: 30px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
}

.table__content tr td.nested-table {
    height: auto;
    height: initial;
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
    border-left: 1px solid #c5c5c5;
}

.table__content thead th {
    color: #fff;
}

.table__content__default-message {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.nested-table > .table__content > tbody > tr {
    padding-left: 100px;
}

/* Table content links */

.table__content a:link {
    color: #1f9992;
}

/* on item active */

.table-item > td:first-of-type,
.table-item > th:first-of-type,
.table-header:first-of-type {
    padding-left: 10px;
}

/* .nested-table__container ~ .table-item {
    opacity: 0.5;
} */

.table-item.active {
    opacity: 1;
}

/* Footer */

.table__content__new-item {
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
}

.table__content__new-item tr:nth-child(2n + 1),
.table__content__new-item tr:nth-child(2n) {
    background-color: #fff !important;
}

@media screen and (min-width: 1440px) {
    .table__content tr th,
    .table__content tr td {
        font-size: 13px;
        height: 35px;
    }
}

@media screen and (min-width: 1920px) {
    .table__content tr th,
    .table__content tr td {
        font-size: 14px;
        height: 40px;
    }
}

@media screen and (min-width: 2300px) {
    .table__content tr th,
    .table__content tr td {
        font-size: 18px;
        height: 60px;
    }
}


.table-item-editable__actions-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    padding: 0 5px;
}

[class*="table-item-editable__actions__button"] {
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table-item-editable__actions__button-check {
    width: 19px;
    height: 19px;
}

.table-item-editable__actions__icon {
    width: 100%;
    height: 100%;
}

.table-item-editable__actions__button-cancel {
    margin-top: 2px;
    width: 15px;
    height: 15px;
}

.table-item-editable__actions__button-delete {
    height: 20px;
    width: 20px;
}

.table-item-editable__actions__button:hover {
    background-color: #ddd;
}

@media screen and (min-width: 2300px) {
    .table-item-editable__actions__button-check {
        width: 26px;
        height: 26px;
    }

    .table-item-editable__actions__button-delete {
        height: 35px;
        width: 35px;
    }

    .table-item-editable__actions__button-cancel {
        margin-top: 2px;
        width: 20px;
        height: 20px;
    }
}

.popover {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.plain-popover {
    display: block !important;
}

.popover.full-width {
    width: 100%;
}

.popover:hover::after {
    content: attr(data-value);
    display: -webkit-flex;
    display: flex;

    -webkit-justify-content: center;

            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    position: absolute;
    bottom: calc(100% + 5px);
    z-index: 999;

    padding: 5px;

    min-width: 50px;

    border-radius: 10px;
    background: #333;

    font-size: 10px;
    text-align: center;

    color: #fff;
}

@media screen and (min-width: 1440px) {
    .popover:hover::after {
        font-size: 11px;
    }
}

@media screen and (min-width: 1920px) {
    .popover:hover::after {
        font-size: 12px;
    }
}

@media screen and (min-width: 2300px) {
    .popover:hover::after {
        min-width: 80px;
        font-size: 16px;
    }
}

.file-input {
    width: 26px;
    height: 26px;
    border-radius: 13px;

    position: relative;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.file-input:hover {
    background-color: #ddd;
}

.file-input__file-icon-container {
    width: 26px;
    height: 26px;
}

.file-input__file-icon {
    width: 100% !important;
    height: 100% !important;
}

.file-input__upload-icon {
    width: 26px;
    height: 26px;
}

.file-input__input {
    display: block;
    width: 26px;
    height: 26px;
    background: #000;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    opacity: 0;
}

@media screen and (min-width: 2300px) {
    .file-input {
        width: 38px;
        height: 38px;
    }
}

.decision-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.decision-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 35px;
    padding-right: 35px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: -webkit-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: max-content;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.decision-modal__title {
    font-size: 15px;
    font-weight: 700;
    color: #1d2934;
}

.decision-modal__text {
    margin-top: 15px;

    width: 350px;

    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
    opacity: 0.5;
}

.decision-modal__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 10px;
    gap: 10px;

    margin-top: 10px;
}

.decision-modal__decision-button {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;

    width: 150px;
    height: 20px;

    font-family: "Montserrat";
    line-height: 1.7;
    font-size: 12px;
    font-weight: 500;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.decision-modal__decision-button:hover {
    cursor: pointer;
}

.decision-modal__decision-button:disabled {
    background-color: #e3aa80;
}

.decision-modal__decision-button:disabled:hover {
    cursor: not-allowed;
}

.decision-modal__cancel-button {
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 18px;

    font-family: "Montserrat";
    line-height: 1.6;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: rgba(0, 0, 0, 0.51);
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.decision-modal__cancel-button:hover {
    cursor: pointer;
}

.data-source-drop {
    margin: 10px 40px 10px -50px;
}

.data-source-drop:hover {
    cursor: pointer;
}

.data-source-drop__selection {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 75%;
    width: 140px;
    font-family: "Roboto";
    background-color: #dee0e3;
    border-bottom: 1px solid #505050;
    border-radius: 3px 3px 0 0;
}

.data-source-drop__text-container {
    margin-left: 10px;
}

.data-source-drop__title {
    font-size: 10px;
    color: #1c9690;
}

.data-source-drop__selected {
    overflow: hidden;
    height: 14px;
    font-size: 12px;
    color: #505050;
}

.data-source-drop__arrow-icon {
    padding-left: 5px;
    margin-right: 2px;
    margin-top: 4px;
}

.data-source-drop__arrow-icon--open {
    padding-left: 5px;
    margin-right: 6px;
    margin-top: 4px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.data-source-drop__options-container {
    display: none;
}

.data-source-drop__options-container--open {
    z-index: 999;
    display: inline;
    display: initial;
    position: absolute;
}

.data-source-drop__option {
    padding: 5px 10px;
    height: 25px;
    width: 140px;
    font-size: 11px;
    color: #505050;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.data-source-drop__option.selected {
    background: rgb(247 247 247);
    color: rgba(0, 0, 0, 0.95);
    font-weight: 500;
}

.data-source-drop__option.suggested::after {
    content: "Best";
    font-size: 9px;
    font-style: italic;
    color: #219992;
}

.data-source-drop__option:hover {
    background-color: #dee0e3;
}

.ui-modal__container {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 1000;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    -webkit-animation: ui-alert__animation-in 0.2s ease-out forwards;

            animation: ui-alert__animation-in 0.2s ease-out forwards;
}

.ui-modal__container.exit {
    -webkit-animation: ui-alert__animation-out 0.2s ease-out forwards;
            animation: ui-alert__animation-out 0.2s ease-out forwards;
}

@-webkit-keyframes ui-alert__animation-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes ui-alert__animation-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes ui-alert__animation-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes ui-alert__animation-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.ui-modal__background {
    width: 100%;
    height: 100%;

    position: absolute;
    background: #000;

    opacity: 0.5;
}

.ui-modal {
    background-color: #fff;

    padding-top: 40em;
    padding-bottom: 28em;
    padding-left: 40em;
    padding-right: 40em;

    max-width: 300px;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);

    font-size: 1px;

    position: relative;
    z-index: 10;
}

.ui-modal__close-button {
    width: 20px;
    height: 20px;

    background: #c9cdd1;
    border: none;
    outline: none;

    border-radius: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    padding: 4px;
    margin: 0;

    cursor: pointer;

    position: absolute;
    top: 13px;
    right: 13px;
}

.ui-modal__title {
    font-size: 15em;
    font-weight: bold;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;

    margin-top: 10px;
    margin-bottom: 10px;

    text-align: center;
}

.ui-modal__description {
    font-size: 12px;
    font-weight: lighter;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;

    margin-bottom: 25px;

    text-align: center;
}

.ui-modal__buttons > button {
    display: block;
    border: none;
    outline: none;

    margin: 0 auto;
    cursor: pointer;

    font-family: "Montserrat";
}

.ui-modal__buttons__principal {
    height: 1.6em;
    border-radius: 0.8em;
    padding-left: 4em;
    padding-right: 4em;

    background-color: #f37920;

    color: #fff;

    margin-bottom: 0.8em !important;

    font-size: 12em;
    font-weight: 500;
}
.ui-modal__buttons__secondary {
    height: 1.6em;
    border-radius: 0.8em;
    padding-left: 2em;
    padding-right: 2em;

    background-color: rgba(0, 0, 0, 0.51);

    color: #fff;

    margin-bottom: 0.8em;

    font-size: 10em;
    font-weight: 500;
}

.ui-notification__container {
    font-size: 1px;

    position: fixed;
    top: 0;

    width: 100%;
    height: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.ui-notification {
    position: fixed;
    top: -3.5em;

    z-index: 1000;

    background-color: #fff;
    border-radius: 0.8em;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

    font-size: 12em;
    font-family: Montserrat;
    font-weight: 500;

    padding-left: 3em;
    padding-right: 3em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;

    -webkit-animation: ui-notification__animation-in 0.3s ease-out forwards;

            animation: ui-notification__animation-in 0.3s ease-out forwards;
}

.ui-notification.exit {
    -webkit-animation: ui-notification__animation-out 0.3s ease-out forwards;
            animation: ui-notification__animation-out 0.3s ease-out forwards;
}

@-webkit-keyframes ui-notification__animation-in {
    from {
        -webkit-transform: translateY(0em);
                transform: translateY(0em);
    }
    to {
        -webkit-transform: translateY(7em);
                transform: translateY(7em);
    }
}

@keyframes ui-notification__animation-in {
    from {
        -webkit-transform: translateY(0em);
                transform: translateY(0em);
    }
    to {
        -webkit-transform: translateY(7em);
                transform: translateY(7em);
    }
}

@-webkit-keyframes ui-notification__animation-out {
    from {
        -webkit-transform: translateY(7em);
                transform: translateY(7em);
    }
    to {
        -webkit-transform: translateY(0em);
                transform: translateY(0em);
    }
}

@keyframes ui-notification__animation-out {
    from {
        -webkit-transform: translateY(7em);
                transform: translateY(7em);
    }
    to {
        -webkit-transform: translateY(0em);
                transform: translateY(0em);
    }
}

.about-modal__image {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 35px;
}

.about-modal__text {
    font-size: 16em;
    font-weight: 500;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;

    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    width: 270px;

    text-align: center;
}

.about-modal__company-logo-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    width: 300px;
}

.about-modal__company-logo {
    margin: auto;
    height: 25px;
    width: 60px;
}

.about-modal__company-logo-image {
    /* margin: auto 20px; */
    width: 100%;
    height: 100%;
}

.about-modal__company-logo__ecmwf {
    margin: auto;

    height: 16px;
    width: 70px;
}

.about-modal__company-logo__noaa {
    /* margin: auto 20px; */
    margin-left: none;
    margin-right: none;
    height: 55px;
    width: 90px;
}

.about-modal__company-logo__open-weather {
    /* margin: auto 20px; */
    /* margin-left: none;
    margin-right: none; */
    height: 30px;
    width: 70px;
}

.table-item.active + tr {
    /* TODO fix !important later */
    background-color: white !important;
}

.table-item.on-edit {
    background-color: white !important;

    border-top: 2px solid #c5c5c5;
    border-bottom: 2px solid #c5c5c5;
}

.table-item.on-edit > td {
    height: auto;
    height: initial;
    padding-top: 10px;
    padding-bottom: 10px;
}

.table-item > td.blur {
    opacity: 0.2;
}

.table-item__field-input {
    width: 100%;
    padding-right: 10px;
}

.table-item__field-input > input,
.table-item-meta-data__field > input {
    width: 100%;

    font-size: inherit;

    background: none;
    border: none;
    outline: none;
}

.table-item__field-input > input[type="text"],
.table-item-meta-data__field > input[type="text"],
.table-item__field-input > input[type="date"],
.table-item-meta-data__field > input[type="date"] {
    width: 80%;
    border-bottom: 1px solid #ddd;
    transition: all 0.2s ease-out;
}

.table-item__field-input > input[type="text"]:focus,
.table-item-meta-data__field > input[type="text"]:focus,
.table-item__field-input > input[type="date"]:focus,
.table-item-meta-data__field > input[type="date"]:focus {
    border-bottom: 2px solid var(--color-climate-green);
}

/* Table Meta Item */

.table-item-meta-data {
    padding-left: 150px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;

    background-color: rgba(0, 0, 0, 0.2);
}

.table-item-meta-data.edit {
    height: auto;
    height: initial;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;

    padding-top: 10px;
    padding-bottom: 10px;

    background-color: white;
    border-bottom: 2px solid #c5c5c5;
}

.table-item-meta-data.edit > .table-item-meta-data__field {
    padding-top: 10px;
}

.table-item-meta-data.edit > .table-item-meta-data__field:first-of-type {
    padding-top: 0px;
}

.table-item-meta-data__field {
    padding-right: 50px;
}

.table-item-meta-data.edit > .table-item-meta-data__field {
    width: 100%;
}

.table-item-meta-data__field > strong {
    padding-right: 5px;
}

.table-item-meta-data__field > input {
    display: inline-block;
    width: 200px !important;
}

/* Checkbox test */
.table-item__checkbox-test {
    position: absolute;
    z-index: 99;
}

.table__draggable-headers {
    width: 100%;
    height: 30px;

    background-color: rgba(128, 128, 128, 0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    padding-left: 100px;
}

.table__draggable-headers__text {
    font-family: "Roboto";
    font-size: 12px;
    color: #1d2934;
}

.draggable-header {
    height: 14px;
    padding-left: 10px;
    border-radius: 7px;
    margin-left: 7px;

    background-color: var(--color-over-menu-bar);

    font-size: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.draggable-header__button {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-left: 14px;
    margin-right: 4px;

    background-color: var(--color-climate-green);

    font-size: 10px;
    color: #fff;
}

@media screen and (min-width: 1440px) {
    .table__draggable-headers__text {
        font-size: 13px;
    }

    .table__draggable-headers {
        height: 35px;
    }
}

@media screen and (min-width: 1920px) {
    .table__draggable-headers__text {
        font-size: 14px;
    }

    .table__draggable-headers {
        height: 40px;
    }
}

@media screen and (min-width: 2300px) {
    .table__draggable-headers__text {
        font-size: 18px;
    }

    .table__draggable-headers {
        height: 60px;
    }
}

.pagination {
    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.pagination__indicator {
    position: relative;

    height: 22px;
    margin-right: 20px;

    font-size: 12px;
}

.pagination__indicator__current-value {
    padding-right: 15px !important;
    padding-left: 15px !important;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    border: 1px solid rgba(0, 0, 0, 0);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.pagination__indicator.active .pagination__indicator__current-value {
    border-color: #000;
}

.pagination__controls {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;

    font-size: 14px;
}

.pagination__controls__button,
.pagination__indicator__current-value__button {
    width: 22px;
    height: 22px;

    border: none;
    background: none;
    outline: none;

    font-size: 20px;
    color: var(--color-inactive-icon);

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    transition: all 0.2s ease-out;
}

.pagination__controls__button.active,
.pagination__indicator__current-value__button.active {
    color: #000;
}

.pagination__indicator.active .pagination__indicator__current-value__button {
    color: var(--color-active-icon);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.pagination__indicator__row-list {
    position: absolute;

    width: 100%;

    display: none;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    border: 1px solid #000;
    border-top-width: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    overflow: hidden;
}

.pagination__indicator.active .pagination__indicator__row-list {
    display: -webkit-flex;
    display: flex;
}

.pagination__indicator__row-list__item {
    width: 100%;
    padding-top: 3px !important ;
    padding-right: 22px !important;
    text-align: center;
}
.pagination__indicator__row-list__item:last-of-type {
    padding-bottom: 3px !important;
}
.pagination__indicator__row-list__item:hover {
    background: #e7e8eb;
}

@media screen and (min-width: 1440px) {
    .pagination__indicator {
        font-size: 13px;
    }

    .pagination__controls {
        font-size: 15px;
    }

    .pagination__controls__button,
    .pagination__indicator__current-value__button {
        font-size: 23px;
    }
}

@media screen and (min-width: 1920px) {
    .pagination__indicator {
        font-size: 14px;
    }

    .pagination__controls {
        font-size: 16px;
    }

    .pagination__controls__button,
    .pagination__indicator__current-value__button {
        font-size: 25px;
    }
}

@media screen and (min-width: 2300px) {
    .pagination__indicator {
        font-size: 18px;
    }

    .pagination__controls {
        font-size: 20px;
    }

    .pagination__controls__button,
    .pagination__indicator__current-value__button {
        font-size: 30px;
    }
}

.checkbox,
.checkbox > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.checkbox {
    position: relative;
    display: inline-block;
}

.checkbox__input {
    width: 14px;
    height: 14px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;

    cursor: pointer;
}

.checkbox__label {
    position: absolute;
    top: 0;
    left: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #323232;

    background-color: none;

    transition: all 0.2s ease-out;
}

.checkbox__input:checked + .checkbox__label {
    border: none;
    background-color: #828282;
}

@media screen and (min-width: 1440px) {
    .checkbox__input {
        width: 16px;
        height: 16px;
    }

    .checkbox__label {
        width: 16px;
        height: 16px;
    }
}

@media screen and (min-width: 1920px) {
    .checkbox__input {
        width: 18px;
        height: 18px;
    }

    .checkbox__label {
        width: 18px;
        height: 18px;
    }
}

@media screen and (min-width: 2300px) {
    .checkbox__input {
        width: 22px;
        height: 22px;
    }

    .checkbox__label {
        width: 22px;
        height: 22px;
        border: 2px solid rgba(50, 50, 50, 0.9);
    }
}

.simple-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;
}

.simple-modal__content {
    min-width: 200px;
    min-height: 100px;

    position: relative;

    background-color: #fff;
    border-radius: 10px;
}

.simple-modal__content__title {
    position: absolute;
    background-color: #fff;

    font-size: 15px;
    font-weight: 700;

    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    bottom: calc(100% + 10px);
}

.simple-button {
    height: 14px;
    padding: 0 12px;

    border-radius: 7px;

    font-size: 10px;
    font-weight: 600;

    border: none;
    outline: none;
    background-color: var(--color-orange-climate);
    color: white;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    cursor: pointer;
}

.email-item {
    width: 115px;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 10px;

    position: relative;
}

.email-item__image {
    width: 32px;
    height: 32px;
    border-radius: 15px;
}

.email-item__info {
    padding-left: 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.email-item__name {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
}
.email-item__email {
    margin: 0;
    font-size: 8px;
    font-weight: 400;

    color: #828282;
}

.email-item__delete {
    position: absolute;
    border: none;
    background: none;
    outline: none;
    width: 16px;
    height: 16px;
    color: white;
    border-radius: 8px;

    cursor: pointer;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    -webkit-transform: rotate(45deg);

            transform: rotate(45deg);

    text-align: center;

    top: 0px;
    right: 0px;

    display: none;
}

.email-item:hover .email-item__delete {
    display: block;
}

.share-report-form {
    width: 415px;

    border-radius: 10px;
    overflow: hidden;
    /* height: 230px; */

    /* padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px; */
}

.share-report-form__emails {
    background-color: #fff;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px;
}

.share-report-form__emails__text {
    font-size: 14px;
}

.share-report-form__emails__title,
.share-report-form__share__title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    padding-bottom: 10px;
}

.share-report-form__emails__title__text,
.share-report-form__share__title__text {
    font-size: 12px;
    font-weight: 500;
    padding-left: 5px;
}

.share-report-form__email__input-container {
    display: -webkit-flex;
    display: flex;
}

.share-report-form__email__input {
    display: block;
    border: none;
    background: none;
    outline: none;

    width: 100%;
    padding: 3px;

    font-size: 10px;

    border-bottom: 1px solid #828282;
}

.share-report-form__email__input.error,
.share-report-form__email__input.error:focus {
    border-bottom: 1px solid var(--color-negative-alert);
}

.share-report-form__email__input:focus {
    border-bottom: 1px solid #1c9690;
}

.share-report-form__email__input-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    /* background: #ddd; */
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.share-report-form__email__input-button:hover {
    background: #ddd;
}

.share-report-form__email__error {
    font-size: 8px;
    color: var(--color-negative-alert);
}

.share-report-form__email__list {
    padding-top: 15px;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-wrap: wrap;

            flex-wrap: wrap;
}

.share-report-form__share {
    background-color: #e3e3e3;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 28px;
}

.share-report-form__share__title__soon {
    padding-left: 5px;
    font-size: 8px;
    -webkit-align-self: flex-end;
            align-self: flex-end;
}

.share-report-form__share__checkbox {
    display: -webkit-flex;
    display: flex;
    padding-left: 6px;
}

.share-report-form__share__checkbox__text {
    padding-left: 6px;
    font-size: 10px;
}

.share-report-form__share__checkbox__button {
    padding-top: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.table-label-button {
    position: relative;
}

.table-label-button__button {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    padding-left: 4px;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table-label-button__button.active,
.table-label-button__button:hover {
    background-color: #ddd;
}

.label-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;

    padding-top: 9px;
}

.label-item__label,
.label-item__delete {
    outline: none;
    border: none;
    background: none;
}

.label-item__label {
    position: relative;

    display: -webkit-flex;

    display: flex;

    -webkit-align-items: center;

            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;

    width: 95px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 5px;

    border-radius: 10px;

    overflow: hidden;
    white-space: nowrap;

    font-size: 10px;
    color: #505050;

    cursor: pointer;
}

.label-item__label::before {
    content: "Select";

    display: block;
    width: 0;

    font-weight: 700;

    opacity: 0;

    /* transition: all 0.2s ease-out; */
}

.label-item__label:hover {
    background: #e7e8eb;
    padding-left: 5px;
}

.label-item__label:hover .label-item__label__color-dot {
    display: none;
}

.label-item__label:hover::before {
    width: auto;
    width: initial;
    padding-right: 3px;

    opacity: 1;
}

.label-item__label__color-dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;

    border-radius: 5px;
}

.label-item__label__name {
    max-width: 65px;
    overflow: hidden;
}

.label-item__delete {
    width: 20px;
    height: 20px;

    border-radius: 10px;
    padding: 2px;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

/* This is going to change later */
.label-item__delete > div {
    width: 100%;
    height: 100%;
}

.label-item__delete:hover {
    background-color: #ddd;
}

.label-select {
    position: absolute;
    z-index: 100;
    top: 20px;
    left: 20px;

    width: 130px;
    padding-top: 10px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 10px;
    border-radius: 10px;
    height: auto;
    height: initial;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.label-select__text {
    padding-bottom: 14px;

    font-size: 10px;
    color: #505050;
}

.label-select__input-container {
    display: -webkit-flex;
    display: flex;

    border-bottom: 1px solid #505050;
}

.label-select__input-container:focus-within {
    border-bottom: 1px solid var(--color-climate-green);
}

/* TODO this class config is being used  mora than 3 times, change it for a component or a generic class */
.label-select__input-container__button {
    width: 20px;
    height: 20px;
    border-radius: 13px;
    padding: 3px;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.label-select__input-container__button:hover {
    background-color: #ddd;
}

.label-select__input-container__input {
    display: block;
    width: 100%;

    font-size: 10px;

    border: none;
    background: none;
    outline: none;
}

.color-picker {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;

    -webkit-align-items: center;

            align-items: center;
}

.color-picker__item {
    display: block;

    outline: none;
    background: none;
    border: none;

    width: 10px;
    height: 10px;
    margin-right: 2px;

    border-radius: 5px;

    cursor: pointer;

    transition: all 0.1s ease-out;
}

.color-picker__item:hover {
    width: 12px;
    height: 12px;

    border: 1px solid #505050;

    border-radius: 6px;
}

.color-picker__item:last-of-type {
    margin-right: 0;
    margin-right: initial;
}

/*********** MAPBOX MAP OVERRIDES ***************/

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-attrib {
    display: none !important;
}

.mapboxgl-popup-content {
    pointer-events: none;
    z-index: 999999;
}

.mapboxgl-popup-close-button {
    display: none;
}

/***********  VARIABLE CONTROL BUTTONS   *****************/
.variable-controls {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    display: -webkit-flex;
    display: flex;
    padding: 8px;
    pointer-events: none;
}

.variable-controls > .variable-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-height: 40px;
    min-width: 38px;
    cursor: pointer;
    background-color: #fafafa;
    pointer-events: all;
    padding: 7px;
    margin: 5px;
    border-radius: 10px;
}

.variable-controls > .variable-button.active {
    background-color: #1c9690;
    color: #fafafa;
}

.variable-controls > .variable-button.active path {
    fill: #fafafa;
}

.variable-controls > .variable-button:hover {
    background-color: #dedede;
    box-shadow: 0px 4px 10px #1c9690;
}

/*********** LAYER SWITCH BUTTON ***************/

.layer-switch {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    z-index: 20;
    -webkit-align-items: center;
            align-items: center;
    min-height: 40px;
    min-width: 38px;
    cursor: pointer;
    background-color: #fafafa;
    pointer-events: all;
    padding: 7px;
    margin: 5px;
    border-radius: 10px;
}

.layer-switch:hover {
    background-color: #dedede;
    box-shadow: 0px 4px 10px #1c9690;
}

/**********  LEGENDS  ***************/

.mapbox-field-legend {
    background-color: none;
    position: absolute;
    z-index: 20;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 8px;
    pointer-events: none;
}

.field-legend-gradient-wrapper {
    background-color: #f4f6f8;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.field-legend-name-wrapper {
    background-color: #f4f6f8;
    padding: 5px;
    border-radius: 5px;
}

.gradient-value {
    font-size: 11px;
    margin: 3px;
}

.gradient-bar {
    height: 130px;
    width: 20px;
    border-radius: 20px;
}

.loading-screen {
    width: 100%;
    height: 100%;
    background-color: #fff;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.loading-screen__title {
    font-family: "Montserrat-Bold";
    font-size: 50px;
    color: #acacac;
}

.loading-screen__loader {
    width: 100%;
    height: 6px;

    position: absolute;
    top: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.loading-screen__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 6px;
    background-color: var(--color-climate-green);

    -webkit-animation: loading-screen__loader--animation 1s ease-out infinite;

            animation: loading-screen__loader--animation 1s ease-out infinite;
}

@-webkit-keyframes loading-screen__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@keyframes loading-screen__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-attrib {
    display: none !important;
}
.MuiCardContent-root:last-child.field-properties__container {
    /* padding: 16px; */
}

.field-properties {
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    /* background: linear-gradient(131.47deg, #f3f9ff 7.84%, #eff2f6 93.19%); */
    background-color: white;
    box-shadow: 0px 4px 10px #c0d2e4;
    border-radius: 10px;
}

.field-properties__mapbox {
    height: 20px;
    width: 100px;
}

.field-properties__info {
    max-width: 100px;
    font-size: 11px;
    margin-top: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

/* .mapContainer.mapboxgl-map {
    width: 100%;
} */

/* Keep an eye on this one, as it overwrites the canvas style of mapboxgl */
.field-properties .field-properties__mapbox .mapboxgl-canvas {
    width: 100% !important;
    height: 90px !important;
    border-radius: 10px;
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .field-properties__info {
        font-size: 10px;
    }
}

.user-profile {
    width: 50px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.user-profile__visible {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
}

.user-profile__image {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.user-profile__deploy-icon--rotate {
    margin-top: 12px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.user-profile__deploy-icon--rotate:hover {
    cursor: pointer;
}

.user-profile__deploy-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-top: 12px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.user-profile__deploy-icon:hover {
    cursor: pointer;
    /* background-color: #dde8f3; */
}

.user-profile__dropdown {
    right: 5px;
    top: 15px;
    position: relative;
    display: inline-block;
}

.user-profile__dropdown-menu {
    display: none;
    /* position: absolute;
    width: 95px;
    height: 140px;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px; */
}

.user-profile__dropdown-menu--open {
    z-index: 1000;
    display: block;
    position: absolute;
    width: 130px;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
}

.user-profile__dropdown-navigation-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.user-profile__dropdown-navigation-container-edgar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.user-profile__dropdown-navigation-container::after {
    display: block;
    margin: 10px auto;
    width: 100%;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    content: "";
}

.user-profile__dropdown-option {
    margin-top: 2px;
    padding: 4px 0px 4px 4px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    color: #505050;
    text-decoration: none;
    cursor: pointer;
}

.user-profile__dropdown-option:hover {
    border-left: 3px solid #1F9992;
    padding-left: 1px;
    background-color: rgba(31, 153, 146, 0.14);
    border-radius: 0px 4px 4px 0px;
}

.user-profile__dropdown__user {
    margin-bottom: 6px;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.user-profile__dropdown-option--selected {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.user-profile__logout-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    cursor: pointer;
    padding: 4px 0px 4px 4px;
}

.user-profile__logout-container:hover {
    border-left: 3px solid #1F9992;
    padding-left: 1px;
    background-color: rgba(31, 153, 146, 0.14);
    border-radius: 0px 4px 4px 0px;
}

.user-profile__logout-text {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    color: #505050;
}

.user-profile__logout-icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-top: 2px;
    margin-right: 6px;
    height: 15px;
    width: 15px;
}

.user-profile__icon {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    /* border: 1px solid var(--color-inactive-icon); */
}

.user-profile__icon.orange {
    background-color: var(--color-orange-climate);
}

.user-profile__icon.green {
    background-color: var(--color-climate-green);
}

@media screen and (min-width: 700px) and (max-width: 1100px) {
    .user-profile__dropdown {
        top: 18px;
        right: 130px;
    }

    .user-profile__deploy-icon--rotate {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

    .user-profile__dropdown-menu--open {
        width: 125px;
        height: -webkit-max-content;
        height: max-content;
        background-color: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .user-profile__dropdown-option {
        margin-top: 15px;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: 400;
        color: #505050;
        text-decoration: none;
    }

    .user-profile__dropdown__user {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .user-profile__dropdown-option--selected {
        margin-top: 15px;
        font-size: 14px;
    }

    .user-profile__logout-text {
        font-size: 14px;
    }

    .user-profile__logout-icon {
        margin-right: 10px;
        height: 16px !important;
        width: 16px !important;
    }
}

.toggle-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.toggle-label {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: rgba(80, 80, 80, 0.7);
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* .toggle-switch input:hover {
  cursor: pointer;
} */

.toggle-round {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939393;
    border-radius: 34px;
    transition: 0.4s;
    cursor: pointer;
}

.disabled .toggle-round {
    cursor: auto;
}

.toggle-round:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input:checked + .toggle-round {
    background: linear-gradient(180deg, #22c11f 0%, #03b800 100%);
}

input:checked + .toggle-round:before {
    -webkit-transform: translateX(16px);
    transform: translateX(16px);
}

.regional-map__toggle__container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
}

.regional-map__toggle__container.active {
    background-color: #1c9690;
}

.regional-map__toggle__icon {
    width: 20px;
    height: 20px;
    margin: 0px 6px;
}

.regional-map__toggle__container.active .regional-map__toggle__icon path {
    fill: white;
}

.table-label-item {
    position: relative;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;

    width: 55px;
    height: 2em;
    margin-right: 1em;
    padding-left: 0.7em;
    padding-right: 0.7em;

    background-color: #ddd;
    border-radius: 1em;

    color: #fff;
    font-size: 0.8em;
    white-space: nowrap;
}

.table-label-item__text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    text-align: center;
}

.table-label-item:last-child {
    margin-right: 0;
    margin-right: initial;
}

.table-item-labels__item__delete {
    display: none;

    position: absolute;
    top: -10px;
    right: -10px;

    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    background: #4e4e4e;

    width: 15px;
    height: 15px;
    padding: 1px;
    border-radius: 7px;
}

.table-label-item:hover .table-item-labels__item__delete {
    display: block;
}

.table-labels {
    width: 100%;

    /* background-color: #000; */

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.table-labels__last-items {
    position: relative;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    height: 1.5em;
    border-radius: 0.75em;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #b1b1b1;
}

.table-labels__last-items__value {
    font-size: 0.8em;
    color: #fff;
}

.table-labels__last-items__container {
    display: none;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 199;

    width: 150px;
    padding-top: 10px;
    padding-left: 10px;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.table-labels__last-items__container .table-label-item {
    margin-bottom: 10px;
}

.table-labels__last-items:hover .table-labels__last-items__container {
    /* .table-labels__last-items__container { */
    display: -webkit-flex;
    display: flex;
}

.weather_variables__temp_container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.max_temp_value {
    color: #424242;
}

.min_temp_value {
    color: #787878;
}

.crop-table {
    width: "100%";

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    padding-bottom: 40px;
}

.crop-table__container {
    width: 900px;
}

.crop-table__icon-button {
    width: 19px;
    height: 19px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.crop-table__toggler-button {
    margin-bottom: 3px !important;
    margin-left: 20px !important;
    border: none;
    background: none;
    outline: none;

    cursor: pointer;
}

.crop-table__toggler-icon {
    width: 5px;
    height: 10px;
}

.crop-table__toggler-icon--open {
    width: 5px;
    height: 10px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.crop-table__icon-button:hover {
    background-color: #ddd;
}

.crop-table__edit-icon {
    width: 16px;
    height: 18px;

    padding: 2px;
}

.crop-table__trash-icon {
    height: 22px;
    width: 22px;

    padding: 2px;
}

.crop-table__check-icon {
    height: 18px;
    width: 18px;

    padding: 1px;
}

.crop-table__alert-icon {
    height: 22px;
    width: 22px;
}

.crop-table__warning-icon {
    height: 18px;
    width: 18px;
}

.green-pct {
    color: green;
}

.red-pct {
    color: red;
}

@media screen and (max-width: 1000px) {
    .crop-table__container {
        padding: 0 30px;
        width: 100%;
    }
}

@media screen and (min-width: 1440px) {
    .crop-table__container {
        width: 1100px;
    }

    .crop-table__toggler-button {
        margin-bottom: 0 !important;
    }

    .crop-table__toggler-icon {
        width: 6px;
        height: 12px;
    }

    .crop-table__toggler-icon--open {
        width: 6px;
        height: 12px;
    }

    .crop-table__edit-icon {
        width: 16px;
        height: 17px;
    }

    .crop-table__trash-icon {
        height: 24px;
        width: 24px;
    }

    .crop-table__alert-icon {
        height: 24px;
        width: 24px;
    }
}

@media screen and (min-width: 1920px) {
    .crop-table__container {
        width: 1350px;
    }

    .crop-table__toggler-icon {
        width: 8px;
        height: 14px;
    }

    .crop-table__toggler-icon--open {
        width: 8px;
        height: 14px;
    }

    .crop-table__edit-icon {
        width: 18px;
        height: 18px;
    }

    .crop-table__trash-icon {
        height: 28px;
        width: 28px;
    }

    .crop-table__alert-icon {
        height: 28px;
        width: 28px;
    }
}

@media screen and (min-width: 2300px) {
    .crop-table__container {
        width: 1600px;
    }

    .crop-table__toggler-icon {
        width: 10px;
        height: 16px;
    }

    .crop-table__toggler-icon--open {
        width: 10px;
        height: 16px;
    }

    .crop-table__icon-button {
        height: 32px;
        width: 32px;
    }

    .crop-table__edit-icon {
        width: 29px;
        height: 29px;
    }

    .crop-table__trash-icon {
        height: 38px;
        width: 38px;
    }

    .crop-table__alert-icon {
        width: 34px;
        height: 34px;
    }
}

.navbar,
.navbar * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    width: 100%;
    height: 100%;
}

.navbar__container {
    /* see if this has to be set fit to 100% or another percentage */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 148px;
    height: 100%;
    box-shadow: var(--shadow-generic);
    background-color: white;
    overflow: hidden;
}

.navbar__header {
    min-height: 40px;
    height: 48px;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.navbar__header-image {
    margin-left: 1%;
    width: 115px;
    height: 45px;
}

.navbar__exit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.navbar__exit:hover {
    cursor: pointer;
}

.navbar__exit-icon {
    margin-top: 5px;
    margin-left: 8%;
}

.navbar__exit-text {
    margin-top: 12px;
    margin-left: 4px;
    font-family: "Roboto";
    font-size: 8px;
}

.navbar__about {
    margin: auto auto 30px auto;
    font-size: 17px;
    color: rgba(29, 41, 52, 0.5);
}

.navbar__about:hover {
    cursor: pointer;
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar__header {
        height: 60px;
    }
    .navbar__header-image {
        width: 135px;
        height: 65px;
    }
    .navbar__container {
        min-width: 175px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar__header {
        height: 75px;
    }
    .navbar__header-image {
        width: 150px;
        height: 70px;
    }
    .navbar__container {
        min-width: 210px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar__container {
        min-width: 400px;
        background: linear-gradient(168.41deg, #f7fafe 0.43%, #eff4fa 105.08%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0 25px 25px 0;
    }

    .navbar__header {
        margin-top: 50px;
        border-bottom: none;
    }

    .navbar__header-image {
        width: 200px;
        height: 32px;
    }
}

.navbar-fields {
    box-sizing: border-box;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    background-color: white;
}

.navbar-fields--highlight {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    background-color: #dde8f3;
}

.navbar-fields__menu {
    margin-bottom: 2px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.navbar-fields__menu__return-button {
    margin-top: 1px;
    margin-left: 15%;
}

.navbar-fields__menu__return-button:hover {
    cursor: pointer;
}

.navbar-fields__menu__text {
    margin-left: 15%;
    -webkit-flex-grow: 2;
            flex-grow: 2;
    display: table-cell;
    vertical-align: middle;
    line-height: 200%;
    font-family: "Roboto";
    font-size: 11px;
    font-weight: 700;
}

.navbar-fields__menu__deploy-button {
    margin-top: 5px;
    margin-right: 7%;
    height: 13px;
    width: 13px;
}

.navbar-fields__menu__deploy-button.rotate {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.navbar-fields__menu__deploy-button:hover {
    cursor: pointer;
}

.navbar-fields__menu__deployable-fields {
    display: none;
}

.navbar-fields__menu__deployable-fields--deployed {
    position: relative;
    display: block;
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-min-content;
    width: min-content;
    background-color: #dde8f3;
    padding-bottom: 10%;
}

.navbar-fields__search-bar {
    margin: auto;
    margin-bottom: 4%;
    height: 25px;
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color: white;
    border: 0.2px solid rgba(29, 41, 52, 0.2);
}

.navbar-fields__search-bar:focus-within {
    border: 0.2px solid var(--color-dark-blue);
}

.navbar-fields__search-bar__icon {
    margin-top: -6px;
    margin-left: 3%;
    width: 34px;
    height: 34px;
}

.navbar-fields__search-bar__input {
    margin-left: 3%;
    width: 108px;
    height: 100%;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 300;
    border: none;

    outline: none;
}

/* The break in the height probably has a relationship with the margin-bottom */
.navbar-fields__fields-list {
    margin: auto;
    margin-top: 8%;
}

.navbar-fields__fields-list__info {
    max-height: 190px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #93c5e3 #c5dff0;
}

.navbar-fields__fields-list__region {
    margin-left: 6%;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: #1d2934;
    padding-bottom: 5px;
}

.navbar-fields__fields-list__items-container {
    margin-bottom: 6px;
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar-fields__menu {
        height: 40px;
    }
    .navbar-fields__menu__return-button {
        margin-top: 5px;
        height: 24px;
        width: 24px;
    }

    .navbar-fields__menu__text {
        margin-top: 2px;
        margin-left: 24px;
        font-size: 16px;
    }

    .navbar-fields__menu__deploy-button {
        margin-top: 8px;
        height: 20px;
    }

    .navbar-fields__search-bar {
        height: 40px;
    }

    .navbar-fields__search-bar__icon {
        width: 42px;
        height: 42px;
    }

    .navbar-fields__search-bar__input {
        margin-left: 10px;
        padding-bottom: 6px;
        font-size: 16px;
    }

    .navbar-fields__fields-list__region {
        font-size: 14px;
    }
} */

/* @media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar-fields__menu {
        height: 50px;
    }
    .navbar-fields__menu__return-button {
        margin-top: 7px;
        height: 30px;
        width: 30px;
    }

    .navbar-fields__menu__text {
        margin-top: 4px;
        margin-left: 30px;
        font-size: 20px;
    }

    .navbar-fields__menu__deploy-button {
        margin-top: 11px;
        margin-right: 20px;
        height: 28px;
    }

    .navbar-fields__menu__deployable-fields--deployed {
        width: 200px;
    }

    .navbar-fields__search-bar {
        height: 40px;
    }

    .navbar-fields__search-bar__icon {
        margin-top: 4px;
        width: 26px;
        height: 26px;
    }

    .navbar-fields__search-bar__input {
        margin-left: 12px;
        padding-bottom: 2px;
        font-size: 19px;
    }

    .navbar-fields__fields-list__region {
        font-size: 16px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar-fields {
        margin-top: 30px;
        margin-left: 100px;
        background-color: transparent;
        border-bottom: none;
    }

    .navbar-fields--highlight {
        margin-top: 30px;
    }

    .navbar-fields--highlight .navbar-fields__menu {
        width: 300px;
    }

    .navbar-fields--highlight .navbar-fields__menu__text {
        padding-left: 100px;
        width: 100px;
    }

    .navbar-fields__menu {
        width: 200px;
    }

    .navbar-fields__menu__return-button {
        display: none;
    }

    .navbar-fields__menu__text {
        margin: 0;
        font-size: 22px;
    }

    .navbar-fields__menu__deploy-button {
        margin: auto 0;
        height: 24px;
        width: 24px;
    }

    .navbar-fields__menu__deployable-fields--deployed {
        margin-left: 90px;
        width: 280px;
    }

    .navbar-fields__search-bar {
        height: 35px;
    }

    .navbar-fields__search-bar__icon {
        margin: auto 0 auto 8px;
        width: 21px;
        height: 21px;
    }

    .navbar-fields__search-bar__input {
        font-family: "Montserrat";
        font-size: 18px;
    }

    .navbar-fields__fields-list__region {
        font-size: 22px;
        font-weight: 500;
    }
}

.navbar-fields-item {
    padding-top: 8px;
    padding-left: 10px;
    padding-bottom: 10px;

    cursor: pointer;
}

.navbar-fields-item:hover {
    background-color: #ddd;
}

/* .navbar-fields-item__image {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    height: 25px;
    width: 25px;
    border-radius: 20%;
} */

.navbar-fields-item__lot {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 500;

    padding-bottom: 5px;
}

.navbar-fields-item__crop {
    font-family: "Roboto";
    font-size: 8px;
    font-weight: 300;
    color: #1d2934;
}
/* 
@media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar-fields-item {
        padding: 13px 0 15px 13px;
    }

    .navbar-fields-item__lot {
        font-size: 16px;
    }

    .navbar-fields-item__crop {
        margin-top: 5px;
        font-size: 14px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar-fields-item {
        padding: 15px 0 18px 19px;
    }

    .navbar-fields-item__lot {
        font-size: 18px;
    }

    .navbar-fields-item__crop {
        margin-top: 8px;
        font-size: 16px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar-fields-item {
        padding: 20px 0 10px 20px;
    }

    .navbar-fields-item__lot {
        font-size: 20px;
    }

    .navbar-fields-item__crop {
        font-size: 18px;
    }
}

.navbar-option__menu {
    margin-top: 6px;
    height: -webkit-max-content;
    height: max-content;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.navbar-option__menu::after {
    display: block;
    margin: 4px auto 0px;
    width: 85%;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    content: "";
}

.navbar-option__menu__tool {
    padding: 2px 0 6px 20px;

    font-family: "Roboto";
    font-size: 11px;
    font-weight: 700;
    color: rgba(29, 41, 52, 0.5);

    text-decoration: none;
}

a.navbar-option__menu__tool:hover {
    background-color: rgba(38, 50, 56, 0.04);
}

.navbar-option__menu__sub-tool {
    /* margin-left: 15%; */
    padding: 0;
    margin: 0;
    font-family: "Roboto";
    text-transform: none;
    font-size: 11px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.5);
    border: none;
}

.navbar-option__menu__sub-tool--active {
    font-weight: 700;
    color: rgba(29, 41, 52);
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar-option__menu__tool {
        padding: 10px 0 10px 20px;
        font-size: 16px;
    }

    .navbar-option__menu__sub-tool {
        margin-top: 5px !important;
        font-size: 15px !important;
    }

    .navbar-option__menu::after {
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar-option__menu__tool {
        padding: 12px 0 12px 28px;
        font-size: 18px;
    }

    .navbar-option__menu__sub-tool {
        margin-top: 8px !important;
        font-size: 17px !important;
    }

    .navbar-option__menu::after {
        margin-top: 12px;
        margin-bottom: 8px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar-option__menu {
        margin-left: 100px;
    }

    .navbar-option__menu::after {
        border-bottom: none;
    }

    .navbar-option__menu__tool {
        padding: 30px 0;
        font-size: 22px;
        color: #1d2934;
    }

    .navbar-option__menu__sub-tool {
        font-size: 22px;
    }
}

.mobile-header {
    box-sizing: border-box;
    padding: 10px 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: linear-gradient(168.41deg, #f7fafe 0.43%, #eff4fa 105.08%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.mobile-header__navbar {
    z-index: 999;
    position: fixed;
    height: 100%;
}

.mobile-header__icon {
    margin: auto 0;
    height: 25px;
    width: 25px;
}

.dashboard * {
    box-sizing: border-box;
}

.dashboard {
    margin: 0;
    padding: 0;
    background: #f4f6f8;
    min-height: 100%;
    height: -webkit-max-content;
    height: max-content;
    /* height: 100%; */
    width: 100%;
    display: -webkit-flex;
    display: flex;
    font-family: "Montserrat";
}

.dashboard__sidebar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 200px;
    /* height: 100%; */
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.dashboard__logo {
    padding: 20px;
    width: 120px;
}

.dashboard__about {
    margin-bottom: 50px;
    margin-left: 40px;
    color: rgba(29, 41, 52, 0.5);
}

.dashboard__about:hover {
    cursor: pointer;
}

.dashboard__header {
    width: 900px;
    margin: 0 auto;
    padding-top: 5em;
    position: relative;
    padding-bottom: 10px;

    display: -webkit-flex;

    display: flex;
    /* justify-content: space-between; */
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.dashboard__header__title {
    margin: 0;
    padding-left: 40px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;

    font-size: 20px;
    font-weight: 600;
}

.dashboard__header__user-info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.dashboard__header__user-info__text {
    padding-left: 8px;
    padding-right: 40px;
}

.dashboard__header__user-info__text__name {
    margin: 0;

    font-size: 10px;
    font-weight: 600;
}
.dashboard__header__user-info__text__logout {
    margin: 0;

    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;

    font-size: 8px;
    font-weight: 400;
}

.dashboard__header__user-info__image {
    width: 32px;
    height: 32px;
    border-radius: 15px;
}

/* @media screen and (min-width: 700px) and (max-width: 1000px) {
    .dashboard__header {
        width: 700px;
    }
} */

@media screen and (max-width: 1100px) {
    .dashboard {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .dashboard__header {
        width: 900px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-top: 0;
        padding-top: 30px;
    }

    .dashboard__logo {
        padding: 10px 0 0 0;
    }
}

@media screen and (max-width: 900px) {
    .dashboard__header {
        width: 700px;
    }
}

@media screen and (min-width: 1440px) {
    .dashboard__header {
        width: 1100px;
    }
}

@media screen and (min-width: 1920px) {
    .dashboard__header {
        width: 1350px;
    }
}

@media screen and (min-width: 2300px) {
    .dashboard__header {
        width: 1600px;
    }
}

.sign-in-side {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-family: "Montserrat";
}

.sign-in-side__main {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.sign-in-side__app-bar-spacer {
    min-height: 64px;
}

.sign-in-side__grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 0;
    gap: 0;
}

.sign-in-side__grid-item__image {
    grid-column: 1 / 2;
    background-image: url(/images/sign_in.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.sign-in-side__grid-item__content {
    grid-column: 2 / 3;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    background-color: #1d2934;
    box-shadow: -1px 0 10px black;
}

.sign-in-side__logo {
    margin-top: 126px;
    min-width: 158px;
    height: 57.5px;
}

.sign-in-side__login-text {
    margin-top: 5px;
    margin-bottom: 18px;
    font-size: 19px;
    font-weight: 600;
    color: white;
}

.sign-in-side__form {
    position: relative;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    margin-top: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.sign-in-side__text-input {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-bottom: 2px;
    width: 70%;
    font-size: 9.5px;
    line-height: 1.6;
    color: white;
    background-color: #1d2934;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}

.sign-in-side__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 15%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: -webkit-max-content;
    max-width: max-content;
    max-height: 11.5px;
}

.sign-in-side__checkbox {
    position: relative;
    display: inline-block;
    margin-left: 1px;
    margin-right: 5px;
    margin-top: 5px;
    width: 12px;
    height: 12px;
}

.sign-in-side__checkbox-input {
    width: 12px;
    height: 12px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;
}

.sign-in-side__checkbox-input:hover {
    cursor: pointer;
}

.sign-in-side__checkbox-label {
    position: absolute;
    top: 0;
    left: 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.sign-in-side__checkbox-input:checked + .sign-in-side__checkbox-label {
    border: none;
    background-color: #1f9992;
}

.sign-in-side__checkbox-text {
    position: absolute;
    padding-left: 20px;

    padding-top: 2px;
    width: 200px;
    font-size: 10px;
    color: white;
}

.sign-in-side__log-button {
    margin-top: 30px;
    margin-bottom: 7px;
    margin-left: auto;
    margin-right: auto;
    width: 138px;
    height: 22px;
    font-size: 9.5px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.sign-in-side__log-button:hover {
    cursor: pointer;
}

.sign-in-side__divider-container {
    margin-top: 30px;
    margin-bottom: 7px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
}

.sign-in-side__divider-line {
    margin-left: 3%;
    margin-right: 3%;
    width: 45%;
}

.sign-in-side__divider-line::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.sign-in-side__divider-text {
    width: 12%;
    text-align: center;
    font-size: 9.5px;
    font-weight: 400;
    color: white;
}

/* .sign-in-side__google-sign-in {
    margin-left: 34%;
    margin-right: 33%;
    margin-top: 15px;
    height: 30px;
    width: 140px;
} */

.sign-in-side__google-sign-in:hover {
    cursor: pointer;
}

.sign-in-side__sign-up {
    margin-top: 15px;
    -webkit-align-self: center;
            align-self: center;
    width: -webkit-max-content;
    width: max-content;
    color: white;
    font-size: 9.5px;
    text-decoration: none;
}

.sign-in-side__sign-up b {
    font-weight: 600;
}

.sign-in-side__restore-password {
    -webkit-align-self: center;
            align-self: center;
    margin-top: 5px;
    margin-bottom: 100px;
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: max-content;
    font-size: 9.5px;
    color: white;
    text-decoration: none;
}

.sign-in-side__copyright {
    margin-bottom: 10px;
    height: 20px;
    color: white;
}

@media screen and (min-width: 1360px) {
    .sign-in-side__logo {
        margin-top: 105px;
        width: 224px;
        min-height: 80.5px;
    }

    .sign-in-side__login-text {
        margin-top: 8px;
        font-size: 24px;
        font-weight: 600;
    }

    .sign-in-side__text-input {
        margin-top: 18px;
        margin-left: 14%;
        margin-right: 14%;
        padding-left: 16px;
        width: 72%;
        font-size: 13px;
        line-height: 1.8;
    }

    .sign-in-side__checkbox-container {
        margin-top: 17px;
        margin-left: 15%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        /* gap: 20px; */
        width: 200px;
    }

    .sign-in-side__checkmark {
        margin-top: 4px;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #1d2934;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .sign-in-side__checkbox-input {
        width: 14px;
        height: 14px;
    }

    .sign-in-side__checkbox-label {
        width: 14px;
        height: 14px;
    }

    .sign-in-side__checkbox-text {
        margin-left: 5px;
        margin-top: -2px;
        width: 200px;
        font-size: 12px;
    }

    .sign-in-side__checkmark--checked {
        margin-top: 4px;
        width: 14px;
        height: 14px;
    }

    .sign-in-side__checkmark--checked::after {
        left: 3px;
        bottom: 0.5px;

        width: 5px;
        height: 11px;

        border-width: 0 3px 3px 0;
    }

    .sign-in-side__log-button {
        margin-top: 40px;
        margin-bottom: 12px;
        width: 210px;
        height: 35px;
        font-size: 13px;
    }

    .sign-in-side__divider-container {
        margin-top: 35px;
        margin-bottom: 12px;
    }

    .sign-in-side__divider-text {
        font-size: 13px;
    }

    .sign-in-side__sign-up {
        margin-top: 20px;

        font-size: 13px;
    }

    .sign-in-side__restore-password {
        margin-top: 10px;
        margin-bottom: 80px;
        font-size: 13px;
    }
}

@media screen and (min-width: 1919px) {
    .sign-in-side__grid {
        grid-template-columns: 65% 35%;
    }

    .sign-in-side__logo {
        margin-top: 105px;
        width: 270px;
        min-height: 87.5px;
    }

    .sign-in-side__login-text {
        margin-top: 16px;
        margin-bottom: 100px;
        font-size: 31px;
        font-weight: 600;
    }

    .sign-in-side__text-input {
        margin-top: 25px;
        margin-left: 14%;
        margin-right: 14%;
        padding-left: 24px;
        width: 67%;
        font-size: 18px;
        line-height: 1.8;
    }

    .sign-in-side__checkbox-container {
        margin-top: 15px;
        margin-left: 15%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: -webkit-max-content;
        width: max-content;
    }

    .sign-in-side__checkmark {
        margin-top: 4px;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #1d2934;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .sign-in-side__checkbox {
        margin-left: 1px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
    }

    .sign-in-side__checkbox-input {
        width: 16px;
        height: 16px;
    }

    .sign-in-side__checkbox-label {
        width: 16px;
        height: 16px;
    }

    .sign-in-side__checkbox-text {
        position: absolute;
        margin-left: 18px;
        margin-top: 0;
        width: 200px;
        font-size: 14px;
    }

    .sign-in-side__checkmark--checked {
        margin-top: 4px;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #1f9992;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .sign-in-side__checkmark--checked::after {
        left: 3px;
        bottom: 0.5px;

        width: 5px;
        height: 11px;

        border-width: 0 3px 3px 0;
    }

    .sign-in-side__log-button {
        margin-top: 60px;
        margin-bottom: 20px;
        width: 210px;
        height: 30px;
        font-size: 14px;
    }

    .sign-in-side__divider-text {
        font-size: 14px;
    }

    .sign-in-side__sign-up {
        margin-top: 30px;

        font-size: 14px;
    }

    .sign-in-side__restore-password {
        margin-top: 15px;
        margin-bottom: 200px;
        font-size: 14px;
    }

    .sign-in-side__copyright {
        margin-bottom: 10px;
        height: 20px;
        color: white;
    }
}

@media screen and (max-width: 1200px) {
    .sign-in-side__grid {
        grid-template-columns: 55% 45%;
    }

    .sign-in-side__logo {
        margin-top: 50px;

        height: 100px;
    }

    .sign-in-side__login-text {
        margin-top: 30px;
        font-size: 30px;
    }

    .sign-in-side__form {
        margin-top: 0;
        margin-bottom: auto;
    }

    .sign-in-side__text-input {
        margin-left: auto;
        margin-right: auto;
        width: 83%;
        font-size: 16px;
        line-height: 2.5;
    }

    .sign-in-side__checkbox-container {
        margin-top: 2em;
        margin-left: 4em;
    }

    .sign-in-side__checkbox {
        width: 22px;
        height: 22px;
    }

    .sign-in-side__checkbox-label {
        width: 22px;
        height: 22px;
        border: 2px solid rgba(255, 255, 255, 0.5);
    }

    .sign-in-side__checkbox-text {
        padding-left: 35px;
        padding-top: 8px;
        font-size: 12px;
    }

    .sign-in-side__log-button {
        margin-top: 50px;
        height: 40px;
        width: 200px;
        font-size: 18px;
    }

    .sign-in-side__divider-text {
        font-size: 18px;
    }

    .sign-in-side__sign-up {
        margin-top: 30px;
        font-size: 18px;
    }

    .sign-in-side__restore-password {
        margin-top: 30px;
        font-size: 18px;
    }

    .sign-in-side__copyright {
        margin-top: auto;
        margin-bottom: 40px;
        width: 180px;
        text-align: center;
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    .sign-in-side__grid {
        grid-template-columns: 1fr 500px 1fr;
        grid-template-rows: 1fr 850px 1fr;
    }

    .sign-in-side__grid-item__image {
        grid-column: 1 / 4;
        grid-row: 1 / 4;
        width: 100%;
        height: 100%;
    }

    .sign-in-side__grid-item__content {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        border-radius: 20px;
    }

    .sign-in-side__logo {
        margin-top: 70px;
        /* margin-bottom: auto; */
        height: 110px;
    }

    .sign-in-side__login-text {
        margin-top: 1.5em;
        margin-bottom: 1em;
        font-size: 30px;
    }

    .sign-in-side__form {
        margin-top: 0;
        margin-bottom: auto;
    }

    .sign-in-side__text-input {
        margin-left: auto;
        margin-right: auto;
        width: 83%;
        font-size: 20px;
        line-height: 2.5;
    }

    .sign-in-side__checkbox-container {
        margin-top: 2em;
        margin-left: 4em;
    }

    .sign-in-side__checkbox {
        width: 24px;
        height: 24px;
    }

    .sign-in-side__checkbox-label {
        width: 24px;
        height: 24px;
        border: 2px solid rgba(255, 255, 255, 0.5);
    }

    .sign-in-side__checkbox-text {
        padding-left: 35px;
        padding-top: 8px;
        font-size: 14px;
    }

    .sign-in-side__log-button {
        margin-top: 50px;
        height: 45px;
        width: 220px;
        font-size: 20px;
    }

    .sign-in-side__divider-text {
        font-size: 20px;
    }

    .sign-in-side__sign-up {
        margin-top: 30px;
        font-size: 20px;
    }

    .sign-in-side__restore-password {
        margin-top: 30px;
        font-size: 20px;
    }

    .sign-in-side__copyright {
        margin-top: auto;
        margin-bottom: 20px;
        width: 300px;
    }
}

.confirmation-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.confirmation-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 35px;
    padding-right: 35px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: -webkit-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: max-content;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.confirmation-modal__title {
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.confirmation-modal__text {
    margin-top: 15px;

    width: 350px;

    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.confirmation-modal__continue-button {
    margin-top: 15px;

    width: 100px;
    height: 16px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.confirmation-modal__continue-button:disabled {
    background-color: #a7b0afe3;
    cursor: not-allowed !important;
}

.confirmation-modal__continue-button:hover {
    cursor: pointer;
}

.change-email-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.change-email-modal__container {
    position: relative;
    bottom: 100px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: -webkit-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: max-content;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.change-email-modal__content-white {
    padding-top: 25px;
    padding-left: 23px;
    padding-bottom: 10px;

    min-height: 50px;

    width: 400px;

    background-color: #fff;

    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.change-email-modal__content-gray {
    padding-top: 20px;
    padding-left: 23px;
    padding-bottom: 15px;

    min-height: 50px;

    width: 400px;

    background-color: #e3e3e3;

    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.change-email-modal__title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.change-email-modal__input {
    padding-top: 10px;
    padding-left: 10px;

    width: 90%;

    font-family: "Roboto";
    line-height: 1.3;
    font-size: 10px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.7);

    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgba(28, 150, 144, 0.6);
}

.change-email-modal__input::-webkit-input-placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-email-modal__input:-ms-input-placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-email-modal__input::placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-email-modal__button-container {
    margin-top: 20px;
    margin-right: 30px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.change-email-modal__cancel-button {
    margin-right: 20px;

    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-email-modal__cancel-button:hover {
    cursor: pointer;
}

.change-email-modal__accept-button {
    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-email-modal__accept-button:hover {
    cursor: pointer;
}

.change-password-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.change-password-modal__container {
    position: relative;
    bottom: 100px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: -webkit-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: max-content;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.change-password-modal__content-white {
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;

    min-height: 50px;

    width: 400px;

    background-color: #fff;

    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.change-password-modal__content-gray {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;

    min-height: 30px;

    width: 400px;

    background-color: #e3e3e3;

    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.change-password-modal__title {
    margin-left: auto;
    margin-right: auto;

    width: 350px;

    text-align: center;
    line-height: 2;
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.change-password-modal__input {
    padding-top: 30px;
    padding-left: 10px;

    width: 95%;

    font-family: "Roboto";
    line-height: 1.3;
    font-size: 10px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.7);

    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgba(28, 150, 144, 0.6);
}

.change-password-modal__input::-webkit-input-placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-password-modal__input:-ms-input-placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-password-modal__input::placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.change-password-modal__button-container {
    margin-right: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.change-password-modal__cancel-button {
    margin-right: 20px;

    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-password-modal__cancel-button:hover {
    cursor: pointer;
}

.change-password-modal__accept-button {
    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-password-modal__accept-button:hover {
    cursor: pointer;
}

.new-password-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.new-password-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 40px;
    padding-left: 23px;
    padding-bottom: 30px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: 400px;
    height: -webkit-max-content;
    height: max-content;

    background-color: #fff;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.new-password-modal__title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.new-password-modal__input {
    padding-top: 10px;
    padding-left: 10px;

    width: 90%;

    font-family: "Roboto";
    line-height: 1.3;
    font-size: 10px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.7);

    background-color: transparent;
    border: 0;
    border-bottom: 2px solid rgba(28, 150, 144, 0.6);
}

.new-password-modal__input::-webkit-input-placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.new-password-modal__input:-ms-input-placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.new-password-modal__input::placeholder {
    color: rgba(80, 80, 80, 0.8);
}

.new-password-modal__button-container {
    margin-top: 10px;
    margin-right: 30px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.new-password-modal__cancel-button {
    margin-right: 20px;

    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.new-password-modal__cancel-button:hover {
    cursor: pointer;
}

.new-password-modal__accept-button {
    width: 100px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.new-password-modal__accept-button:hover {
    cursor: pointer;
}

.change-picture-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.change-picture-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;

    width: 400px;
    height: -webkit-max-content;
    height: max-content;

    background-color: #fff;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.change-picture-modal__title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(29, 41, 52, 0.85);
}

.change-picture-modal__image {
    margin-top: 20px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    width: 150px;
    height: 150px;

    text-transform: uppercase;
    font-size: 80px;
    font-weight: 600;
    color: white;

    background-color: var(--color-orange-climate);
    border-radius: 50%;
}

.change-picture-modal__browse-button {
    margin-top: 20px;

    width: 260px;
    height: 25px;

    line-height: 1.7;
    font-size: 13px;
    font-weight: 500;
    color: white;

    background-color: #525252;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-picture-modal__button-container {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    width: 260px;
}
.change-picture-modal__delete-button {
    width: 80px;
    height: 15px;

    line-height: 1.7;
    font-size: 8.5px;
    font-weight: 400;
    color: #525252;

    background-color: transparent;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-picture-modal__cancel-button {
    width: 80px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.change-picture-modal__confirm-button {
    width: 80px;
    height: 15px;

    line-height: 1.7;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: #1c9690;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
    z-index: 999;
}

.settings__menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: 100%;
    height: -webkit-max-content;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.settings__menu-header {
    display: -webkit-flex;
    display: flex;
    margin-top: 50px;
    margin-left: 3%;
    padding-bottom: 10px;
}

.settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.settings__header-title {
    margin-top: -2px;
    margin-left: 1%;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 250px;
}

.settings__user-profile {
    margin-left: 500px;
}

/* .settings__menu-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
} */

.settings__title {
    display: -webkit-flex;
    display: flex;

    margin-top: 10px;
    margin-left: 6%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.settings__title-dot {
    margin-right: 3px;

    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings {
    box-sizing: border-box;
    margin-left: 3%;
    width: 800px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px #c0d2e4;
}

.profile-settings__grid {
    display: grid;
    grid-template-columns: 30% 35% 35%;
}

.profile-settings__image-column {
    grid-column: 1 / 2;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.profile-settings__user-profile__icon {
    margin-top: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100px;
    height: 100px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 600;
    color: white;
    background-color: var(--color-orange-climate);
    border-radius: 50%;
}

.profile-settins__user-profile__icon.green {
    border: 2px solid var(--color-climate-green);
    color: var(--color-climate-green);
}

/* .profile-settings__image-column__title {
    margin-top: 20px;
    color: #1d2934;
    font-size: 15px;
    font-weight: 700;
} */

.profile-settings__image-column__image {
    margin-top: 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.profile-seetings__image-column__edit-icon-container {
    position: relative;
    z-index: 100;
    left: 32px;
    bottom: 27px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 28px;
    height: 28px;
    background-color: #1c9690;
    border-radius: 50%;
}

.profile-seetings__image-column__edit-icon-container:hover {
    cursor: pointer;
}

.profile-settings__info-column {
    grid-column: 2 / 3;
    margin-top: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* align-items: center; */
    /* height: 100%; */
}

.profile-settings__info-column__title-text {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__info-column__info-container {
    margin-top: 7px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 80%;
    border-bottom: 1px solid #1c9690;
}

.profile-settings__info-column__info-container.error {
    border-bottom: 1px solid var(--color-negative-alert);
}

.profile-settings__info-column__info-text {
    margin-left: 2%;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    border: 0px;
    width: 75%;
}

.profile-settings__info-column__info-text:disabled {
    color: #7e7e7e;
    background: none;
}

.profile-settings__info-column__edit-icon-container {
    margin-right: 3%;
    height: 13px;
    width: 13px;
}

.profile-settings__info-column__edit-icon-container:hover {
    cursor: pointer;
}

.profile-settings__info-column__edit-confirmation-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    margin-left: -20px;
    height: 20px;
    width: -webkit-max-content;
    width: max-content;
}

.profile-settings__info-column__edit-confirmation-container__confirm {
    width: 15px;
    height: 15px;
}

.profile-settings__info-column__edit-confirmation-container__cancel {
    width: 15px;
    height: 15px;
}

.profile-settings__info-column__info-container__error-message {
    position: absolute;
    font-size: 12px;
    color: red;
}

.profile-settings__info-column__info-password {
    margin-left: 2%;
    margin-bottom: 3px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    color: #505050;
}

.profile-settings__info-column__change-password-option {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    margin-right: 20%;
    margin-top: 7px;
    font-size: 9px;
    color: #1c9690;
    text-decoration: none;
    cursor: pointer;
}

.profile-settings__unit-column {
    grid-column: 3 / 4;
    margin-top: 68px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.profile-settings__unit-column__title {
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__unit-column__text {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.profile-settings__button-container {
    margin-top: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 15px;
    gap: 15px;
}

.profile-settings__button {
    display: -webkit-flex;
    display: flex;
    width: 90px;
    height: 22px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.profile-settings__button:hover {
    cursor: pointer;
}

.profile-settings__button.selected {
    display: -webkit-flex;
    display: flex;
    width: 90px;
    height: 22px;
    background-color: #1c9690;
    border-radius: 15px;
    border: 0;
}

.profile-settings__button-text {
    margin-top: 3px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__button.selected .profile-settings__button-text {
    margin-top: 3px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.profile-settings__button-icon {
    margin-top: 3px;
    margin-left: auto;
    margin-right: 2px;
    width: 14px;
    height: 14px;
    background-color: rgba(29, 41, 52, 0.25);
    /* border: 3px solid white; */
    border-radius: 50%;
}

.profile-settings__button.selected .profile-settings__button-icon {
    margin-top: 4px;
    margin-left: auto;
    margin-right: 2px;
    width: 8px;
    height: 8px;
    border: 3px solid white;
    border-radius: 50%;
}

.hidden {
    display: none;
}

@media screen and (max-width: 1100px) {
    .profile-settings {
        margin: 0;
        padding: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .profile-settings__grid {
        grid-template-columns: 50% 50%;
        grid-template-rows: 70% 30%;
    }

    .profile-settings__user-profile__icon {
        width: 144px;
        height: 144px;
        font-size: 80px;
    }

    .profile-settings__info-column__title-text {
        font-size: 18px;
        font-weight: 400;
    }

    .profile-settings__info-column__info-text {
        font-size: 22px;
        font-weight: 600;
    }

    .profile-settings__info-column__info-text:disabled {
        color: #1d2934;
    }

    .profile-settings__info-column__info-password {
        font-size: 22px;
        color: #1d2934;
    }

    .profile-settings__info-column__change-password-option {
        font-size: 18px;
        font-weight: 500;
    }

    .profile-settings__unit-column {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    .profile-settings__unit-column__title {
        font-size: 22px;
    }

    .profile-settings__unit-column__text {
        margin-top: 20px;
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 400;
    }

    .profile-settings__button-container {
        margin-top: 20px;
    }

    .profile-settings__button {
        width: 120px;
        height: 28px;
    }

    .profile-settings__button.selected {
        width: 120px;
        height: 28px;
    }

    .profile-settings__button-text {
        margin-left: 10px;
        font-size: 14px;
    }

    .profile-settings__button.selected .profile-settings__button-text {
        font-size: 14px;
    }

    .profile-settings__button-icon {
        width: 18px;
        height: 18px;
    }

    .profile-settings__button.selected .profile-settings__button-icon {
        width: 12px;
        height: 12px;
        border: 3x solid white;
    }
}

.alert-select {
    margin-left: 10px;
    margin-right: 10px;

    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    color: #505050;

    background-color: #e9ebef;
}

.hidden {
    display: none;
}

.alert-select__control-container {
    padding: 3px 10px 3px 10px;

    /* Set this to different options later */

    min-width: 16px;
    min-height: 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #828282;
    cursor: pointer;
}

.alert-select__control-arrow {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-left: 10px;

    color: #505050;
}

.alert-select__control-arrow.rotate {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.alert-select__options-dropdown {
    position: absolute;
    max-height: 80px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #939393 transparent;
}

.alert-select__option {
    padding: 3px 10px 3px 10px;
    min-width: 28px;
    min-height: 10px;
    background-color: #e9ebef;
    border-bottom: 1px solid rgba(130, 130, 130, 0.5);
}

.delete-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";

    background-color: rgba(0, 0, 0, 0.5);
}

.delete-modal__container {
    position: relative;
    bottom: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 35px;
    padding-right: 35px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    width: -webkit-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: max-content;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.delete-modal__title {
    font-size: 15px;
    font-weight: 700;
    color: #1d2934;
}

.delete-modal__text {
    margin-top: 15px;

    width: 350px;

    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
    opacity: 0.5;
}

.delete-modal__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 10px;
    gap: 10px;

    margin-top: 10px;
}

.delete-modal__delete-button {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;

    width: 150px;
    height: 20px;

    font-family: "Montserrat";
    line-height: 1.7;
    font-size: 12px;
    font-weight: 500;
    color: white;

    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.delete-modal__delete-button:hover {
    cursor: pointer;
}

.delete-modal__cancel-button {
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 18px;

    font-family: "Montserrat";
    line-height: 1.6;
    font-size: 10.5px;
    font-weight: 400;
    color: white;

    background-color: rgba(0, 0, 0, 0.51);
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.delete-modal__cancel-button:hover {
    cursor: pointer;
}

.alert-settings {
    box-sizing: border-box;

    width: 100vw;
    height: 100vh;

    font-family: "Montserrat";

    /* TODO: see why there's an overflow scroll beyond the container */
    overflow-x: hidden;
}

.alert-settings__container {
    display: -webkit-flex;
    display: flex;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

/* TODO: Fix the problem with the navbar height */
.alert-settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
    z-index: 999;
}

.alert-settings__menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: 100%;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.alert-settings__menu__loader {
    width: 100%;
    height: 4px;

    position: absolute;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.alert-settings__menu__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 4px;
    background-color: var(--color-climate-green);

    -webkit-animation: alert-settings__menu__loader--animation 1s ease-out infinite;

            animation: alert-settings__menu__loader--animation 1s ease-out infinite;
}

@-webkit-keyframes alert-settings__menu__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@keyframes alert-settings__menu__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

.alert-settings__menu-header {
    display: -webkit-flex;
    display: flex;
    margin-top: 50px;
    margin-left: 3%;
    padding-bottom: 10px;
}

.alert-settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.alert-settings__header-title {
    margin-top: -2px;
    margin-left: 1%;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 200px;
}

.alert-settings__user-profile {
    margin-left: 500px;
}

/* .alert-settings__menu-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
} */

.alert-settings__title {
    display: -webkit-flex;
    display: flex;

    margin-top: 10px;
    margin-left: 6%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__title-dot {
    margin-right: 3px;

    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__text {
    margin-top: 5px;
    margin-left: 8%;

    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.alert-settings__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 19%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: -webkit-max-content;
    max-width: max-content;
    max-height: 11.5px;
}

.alert-settings__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: white;
    border: 1px solid #505050;
}

.alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #1d2934;
    border: 1px solid #505050;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__alerts-menu-container {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 4%;

    width: 100%;
    height: 100%;
}

.alert-settings__alerts__crop-menu-container {
    padding-left: 50px;
    margin: 0px;
    width: 700px;

    display: -webkit-flex;

    display: flex;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;

    list-style: none;
}

.alert-settings__alerts__crop-menu {
    padding: 12px;

    width: -webkit-max-content;

    width: max-content;

    font-weight: 400;
}

.react-tabs__tab--selected .alert-settings__alerts__crop-menu {
    display: -webkit-flex;
    display: flex;

    font-weight: 600;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.react-tabs__tab--selected .alert-settings__alert__crop-menu-icon {
    display: block;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-left: 8px;
    margin-top: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.alert-settings__alerts__crop-menu:hover {
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
    cursor: pointer;
}

.alert-settings__alerts__crop-menu--selected:hover {
    cursor: pointer;
}

.alert-settings__alerts__crop-menu-title {
    font-size: 13px;
}

.alert-settings__alert__crop-menu-icon {
    display: none;
}

.variety-alert__controls {
    display: -webkit-flex;
    display: flex;
}

/* .variety-alert__controls__download-button {
}

.variety-alert__controls__upload-file {
} */

/* TODO: Check why the Tab List creates an extra space above when it has more than 1 variety */
.alert-settings__alerts__variety-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    list-style: none;
    overflow-x: scroll;
}

.alert-settings__alerts__variety-button {
    height: 24px;
    width: -webkit-max-content;
    width: max-content;
    /* background-color: rgba(28, 150, 144, 0.3); */
    /* background-color: #fff; */
    background: none;
    outline: none;
    cursor: pointer;
    color: #000;
    border: none;
    /* border-radius: 20px; */

    border-right: 1px solid #000;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-button {
    font-weight: bold;
}

.alert-settings__alerts__variety-title {
    padding: 2px 8px;
    width: -webkit-max-content;
    width: max-content;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    /* color: #1d2934; */
}

.react-tabs__tab--selected .alert-settings__alerts__variety-title {
    font-weight: 700;
    /* color: white; */
}

.alert-settings__alerts-container {
    position: relative;

    padding: 0 10px 20px 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;

    width: 750px;
    height: -webkit-max-content;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.alert {
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";
}

.hidden {
    display: none;
}

.opacity {
    opacity: 50%;
}

.alert__container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 16px 0px;

    width: 750px;
    height: -webkit-max-content;
    height: max-content;

    border-radius: 10px;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
    position: relative;
}

.alert__header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    height: 20px;
}

.alert__header__plus-icon {
    height: 24px;
    width: 24px;
    margin-left: 15px;
    cursor: pointer;
}

.disabled {
    cursor: auto;
    opacity: 50%;
}

.alert__header__title {
    margin-left: 10px;
    /* margin-right: auto; */
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.alert__header__custom-icon {
    margin-left: 10px;
    margin-top: 2px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    width: 17px;
    height: 17px;

    background-color: #1d2934;

    border-radius: 50%;
}

.alert__header__alert-count {
    margin-right: auto;
    margin-left: 10px;
    font-size: 14px;
}

.alert__header__arrow-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);

    margin-left: auto;
    margin-right: 50px;

    height: 15px;
    width: 15px;
}

.alert__header__arrow-icon.upside {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.alert__header__arrow-icon:hover {
    cursor: pointer;
}

.alert__content {
    padding: 10px;
}

.alert__content__header {
    margin-left: 30px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.alert__content__title-dot {
    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-input {
    height: 10px;
    width: 50%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-input::-webkit-input-placeholder {
    color: lightgray;
}

.alert__content__title-input:-ms-input-placeholder {
    color: lightgray;
}

.alert__content__title-input::placeholder {
    color: lightgray;
}

.alert__content__title-input:first-line {
    display: inline-block;
}

.alert__content__title {
    margin-left: 10px;

    width: 60%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-error {
    font-size: 12px;
    color: red;
    position: absolute;
    top: 24px;
    left: 14px;
}

.alert__content__slider-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.alert__content__slider-label {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: rgba(80, 80, 80, 0.7);
}

.alert__content__slider-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
}

.alert__content__slider-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* .alert__content__slider-switch input:hover {
    cursor: pointer;
} */

.alert__content__slider-round {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939393;
    border-radius: 34px;
    transition: 0.4s;
    cursor: pointer;
}

.disabled .alert__content__slider-round {
    cursor: auto;
}

.alert__content__slider-round:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input:checked + .alert__content__slider-round {
    background: linear-gradient(180deg, #22c11f 0%, #03b800 100%);
}

input:checked + .alert__content__slider-round:before {
    -webkit-transform: translateX(16px);
    transform: translateX(16px);
}

.alert__content__conditions {
    padding: 20px;
    padding-left: 40px;
}

[class^="alert__content__conditions__options-"] {
    margin-bottom: 5px;

    display: -webkit-flex;

    display: flex;

    font-family: "Roboto";
    font-size: 13px;
    color: #505050;
}

[class^="alert__content__conditions__options-"] strong {
    color: #1d2934;
}

.alert__content__conditions__options-m-margin {
    margin-left: 20px;
}

.alert__content__conditions__options-l-margin {
    margin-top: 25px;
    margin-left: 40px;
}

.alert__content__conditions__container {
    max-width: 75px;
}

.alert__content__conditions__input {
    margin-right: 8px;
    margin-left: 8px;

    height: 16px;
    width: 50px;

    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #2c2c2c;

    background-color: white;
    border: 1px solid rgba(130, 130, 130, 0.5);
    border-radius: 5px;
}

.alert__content__conditions__input::-webkit-input-placeholder {
    color: lightgray;
}

.alert__content__conditions__input:-ms-input-placeholder {
    color: lightgray;
}

.alert__content__conditions__input::placeholder {
    color: lightgray;
}

.alert__content__conditions__input:disabled {
    background-color: #e9ebef;
}

.alert__content__conditions__input .alert__content__conditions__input:disabled {
    color: #2c2c2c;
    background-color: #e2e2e2;
}

.alert__content__conditions__input-error {
    color: red;
    margin-left: -100%;
    min-width: 400%;
    font-family: "Montserrat";
    font-size: 12px;
}

.alert__content__edit-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    grid-gap: 20px;
    gap: 20px;

    margin-right: 30%;
}

.alert__content__edit-icon {
    height: 13px;
    width: 13px;
}

.alert__content__edit-icon:hover {
    cursor: pointer;
}

.alert__content__trash-icon {
    height: 22px;
    width: 22px;
}

.alert__content__trash-icon:hover {
    cursor: pointer;
}

.alert__content__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    grid-gap: 20px;
    gap: 20px;

    margin-right: 20px;
}

.alert__content__cancel-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__cancel-button:hover {
    cursor: pointer;
}

.alert__content__save-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__save-button:hover {
    cursor: pointer;
}

.alert__content__divider {
    height: 20px;
}

.alert__content__divider::after {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-bottom: 1px solid rgba(29, 41, 52, 0.5);
}

.alert__header__loading-bar {
    position: absolute;
    top: 1px;
    width: 96%;
    left: 2%;
}


.table__loader {
    width: 100%;
    height: 3px;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.table__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 3px;
    background-color: #1F9992;

    -webkit-animation: table__loader--animation 1s ease-out infinite;

            animation: table__loader--animation 1s ease-out infinite;
}

@-webkit-keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

.alert-icon {
    position: relative;
}

.alert-icon__indicator {
    border-radius: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #ff3d3d;

    position: absolute;
    bottom: 0%;
    right: -8%;

    width: 10px;
    height: 10px;

    font-size: 9px;
    color: #fff;
}

.thermometer-up-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.thermometer-down-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.heat-soil-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.heat-soil-icon__sun {
    margin-left: 3px;
    margin-top: 1px;
    height: 6px;
}

.heat-soil-icon__soil {
    margin-left: 1px;
    margin-bottom: 2px;
}

.cold-soil-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.cold-soil-icon__snow {
    margin-left: 1px;
    margin-top: 1px;
    height: 6px;
    width: 8px;
}

.cold-soil-icon__soil {
    margin-bottom: 2px;
}

@media screen and (min-width: 1440px) {
    .alert-icon__indicator {
        width: 11px;
        height: 11px;
        bottom: -1%;
        right: -7%;
    }
}

@media screen and (min-width: 1920px) {
    .alert-icon__indicator {
        width: 12px;
        height: 12px;
    }
}

@media screen and (min-width: 2300px) {
    .alert-icon__indicator {
        width: 15px;
        height: 15px;
        right: -5%;
    }
}

.icon-button {
    width: 140px;
    height: 26px;

    position: relative;

    border-radius: 13px;
    background: var(--color-climate-green);

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.icon-button__file-input {
    position: absolute;

    border: none;
    background: none;
    outline: none;
    opacity: 0;

    width: 100%;
    height: 100%;
    cursor: pointer;
}

.icon-button__button {
    position: absolute;

    border: none;
    background: none;
    outline: none;
    opacity: 0;

    width: 100%;
    height: 100%;
    cursor: pointer;
}

.icon-button__content {
    font-size: 10px;
    font-weight: bold;
    color: white;
}

.table-settings {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.table-settings__container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.table-settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
}

.table-settings__menu {
    box-sizing: border-box;
    width: 100%;
}

.table-settings__menu-header {
    margin-top: 50px;
    margin-left: 3%;
    display: -webkit-flex;
    display: flex;
}

.table-settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.table-settings__header-title {
    margin-left: 1%;
    margin-top: -2px;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 200px;
}

.table-settings__user-profile {
    margin-left: 500px;
}

.table-settings__card {
    margin-left: 3%;
    width: 800px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px #c0d2e4;
    padding: 12px;
}

.table-settings__card.small {
    width: 300px;
    padding: 12px 24px;
}

.table-settings__card-title {
    font-weight: 500;
}

.table-settings__card-subtitle {
    font-size: 14px;
    margin-top: 6px;
}

.columns-checkbox-container {
    margin-top: 8px;
    max-height: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.checkbox-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 4px;
}

.checkbox {
    position: relative;
}

.checkbox-input {
    display: block;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label {
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid rgb(0 0 0 / 60%);
}

.checkbox-text {
    position: absolute;
    margin-left: 18px;
    font-size: 12px;
    color: black;
    cursor: pointer;
}

.table-settings__save-button__container {
    text-align: center;
}

.table-settings__save-button {
    width: 90px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px;
    margin: auto;
}

.table-settings__save-button:disabled {
    background-color: #a7b0afe3;
    cursor: not-allowed !important;
}

.table-settings__save-button:hover {
    cursor: pointer;
}

.checkbox-disabled {
    cursor: auto;
    opacity: 0.75;
}

@media screen and (max-width: 900px) {
    .table-settings__card {
        width: 580px;
    }

    .table-settings__user-table {
        margin-left: 300px;
    }

    .table-settings__card-grid {
        grid-template-columns: 25% 35% 35%;
    }
}

.chart-grid {
    display: grid;
    grid-template-columns: 20px 1fr minmax(120px, auto);
    grid-gap: 30px;
}

.chart-block {
    display: grid;
    grid-template-columns: auto calc(100% - 40px);
}

.flexible-chart {
    width: calc(100% - 40px);
}

p.y-label {
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    text-align: center;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    color: #1c9690;
}

.y-axis .rv-xy-plot__axis__tick__text {
    fill: #1c9690 !important;
}

.headerBlocksContainer {
    margin-top: 20px;
    margin-bottom: 10px;
    display: grid;
    /*grid-template-columns: repeat(5, minmax(135px, 1fr)) 46px minmax(196px, 1fr);*/
    grid-template-columns: 1fr 4fr 50px minmax(250px, 1fr);
}

.headerBlockContainer-hover:hover {
    box-shadow: 0px 4px 10px #1c9690;
}

/* TODO: Better naming convention */
.headerBlockContainer {
    /* margin-right: 18px; */
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 90px;
    width: 110px;
    box-shadow: 0px 4px 10px #c0d2e4;
    border-radius: 5px;
    transition: 200ms;
    border: 1px solid #1c9690;
    cursor: pointer;
}

.headerBlockContainer:last-child {
    margin-right: 0;
}

.headerBlockContainer.profileBlock {
    border: 0;
    padding: 5px 11px;
    height: 54px;
}

.profileText {
    font-size: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.profileTextBold {
    font-size: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 4px;
}

.profileTextBold:last-child {
    margin-bottom: 0;
}

.profileTextContainer {
    margin-right: 17px;
}

.headerBlock {
    box-shadow: none !important;
    background: transparent !important;
}

p.titleText {
    padding: 0 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.02em;
    margin-bottom: 0 !important;
    padding-top: 9px;
}

.infoContainer {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.blocks-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-right: 18px;
}

.textBlockContainer {
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    border-radius: 20px;
    height: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid #1f9992;
    cursor: pointer;
    transition: 200ms;
}

.textBlockContainer:hover {
    box-shadow: 0px 4px 10px #1c9690;
}

p.textBlock {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    color: #1d2934;
}

.dotIcon {
    padding-right: 0;
    padding-left: 0;
    width: 24px;
}

.title-container {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

p.main-title {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 24px;
}

.main-titles {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 24px !important;
    fill: #263238 !important;
}

.main-titles text {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 12px !important;
    fill: #263238 !important;
}

.desc-card {
    padding: 13px 22px 7px 31px;
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    border-radius: 10px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}

.profileTextContainer {
    margin-right: 20px;
}

p.profile-bold {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 18px;
}

span.profile-light {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    margin-left: 10px;
}

.btns-container {
    margin-bottom: 10px;
}

.btns-container button {
    padding: 10px 20px;
    cursor: pointer;
    border: 0;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
}

.content-dropdown {
    padding: 30px 10px 10px 10px;
    box-shadow: 0px 4px 10px rgba(56, 78, 99, 0.2);
    width: 96px;
    border-radius: 0 0 10px 10px;
    background: #fff;
    margin-top: -20px;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
}

button.button-dropdown {
    background: #f3f7fb;
    display: -webkit-flex;
    display: flex;
    box-shadow: 0px 4px 10px #c0d2e4;
    border: 1px solid #1c9690;
    font-size: 10px;
    padding: 8px 12px;
    color: #1c9690;
    width: 116px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
    z-index: 999;
    font-weight: 600;
}

button.button-dropdown:focus {
    outline: none;
}

button.button-dropdown img {
    margin-right: 4px;
}

button.export-now {
    border: 0;
    background: #1c9690;
    border-radius: 20px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 4px 10px;
    margin-bottom: 10px;
    font-size: 10px;
    cursor: pointer;
}

button.export-now:focus {
    outline: none;
}

.dropdown-item {
    display: grid;
    grid-template-columns: 10px auto;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 10px;
}

label.dropdown-item-text {
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    margin: 0;
}

p.dropdown-item-text {
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    margin: 0;
}

span.dropdown-item-text-bold {
    font-weight: 700;
}

.hidden-link {
    display: none;
}

.more-content-dropdown {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #fff;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
}

.more-item-dropdown {
    padding: 6px 10px;
    font-size: 10px;
    font-family: "Roboto", serif;
    color: #1d2934;
    cursor: pointer;
}

.more-item-dropdown:nth-child(even) {
    background: #e7e7e7;
}

.more-item-dropdown:last-child {
    border-radius: 0 0 5px 5px;
}

.more-dropdown-container {
    z-index: 9997 !important;
    margin-left: -200px;
}

.buttonDropdownLeftSide {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.mainProfileBlockContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.mainProfileBlock {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.mainProfileBlockText {
    margin-left: 6px;
}

p.mainProfileBlockTitle {
    font-family: "Montserrat", serif;
    color: #1d2934;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
}

p.mainProfileBlockDesc {
    font-family: "Montserrat", serif;
    color: #505c66;
    font-size: 8px;
    font-weight: 400;
    margin: 0;
    padding-bottom: 2px;
}

.chart-preload-container {
    width: 100%;
    height: 500px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.slider-container {
    margin-top: 30px;
}

.Toastify__toast-body {
    font-family: "Montserrat";
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
    .headerBlocksContainer {
        grid-template-columns: 25% 68% 5%;
    }

    .headerBlockContainer {
        height: 90px;
    }

    .blocks-container {
        padding-top: 20px;
    }

    .textBlockContainer {
        height: 38px;
    }
    .textBlockContainer p {
        font-size: 18px;
    }

    .chart-actions__dropdown-button {
        /* margin-top: 120px; */
        margin-top: auto;
        margin-bottom: auto;
    }

    p.titleText {
        font-size: 15px;
    }

    /* .infoContainer {
        height: inherit;
    } */
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    /* .headerBlockContainer {
        width: 150px;
    } */
}

.main {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.main__container {
    display: -webkit-flex;
    display: flex;
    /* background: red; */
    background: linear-gradient(131.47deg, #f3f9ff 7.84%, #eff2f6 93.19%);
}

.main__content {
    padding-left: 2em;
    padding-right: 4em;
    min-height: 100vh;
    height: 100%;
    width: 100%;
}

.main__header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 2em;
    margin-right: 4em;
    margin-top: 3em;
}

.main__header__menu-icon {
    display: none;
}

.main__header__title-container {
    margin-right: auto;
    display: -webkit-flex;
    display: flex;
}

.main__header__title-return-icon {
    margin-top: 3px;
    width: 24px;
    height: 24px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.main__header__title-return-icon.clickable {
    cursor: pointer;
}

.main__header__title-model {
    margin-left: 10px;
    font-family: "Montserrat";
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
}

.main__header__title-icon {
    margin-top: 3px;
    width: 24px;
    height: 24px;
}

.main__header__title-field {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    color: #1d2934;
}

.main__header__options-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

@media screen and (max-width: 1100px) {
    .main {
        overflow-x: hidden;
    }

    .main__container {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    
    .main__content {
        padding-right: 1em;
        padding-left: 1em;
    }

    .main__header {
    }

    .main__header__navbar {
        z-index: 999;
        width: 100%;        
    }

    .main__header__menu-icon {
        display: block;
        width: 28px;
        height: 28px;
    }

    /* .main__header__title-return-icon {
        display: none !important;
    } */

    .main__header {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-right: 3em;
    }
}

@media screen and (max-width: 800px) {
    .main__header__title-container {
        margin-left: auto;
    }
}

.settings-layout {
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    overflow-y: hidden;

    width: 100%;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;

    font-family: "Montserrat";

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.settings-layout__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
}

.settings-layout__content {
    margin-left: 15px;
}

@media screen and (max-width: 1100px) {
    .settings-layout {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.alert-settings {
    box-sizing: border-box;

    width: 100vw;
    height: 100vh;

    font-family: "Montserrat";

    /* TODO: see why there's an overflow scroll beyond the container */
    overflow-x: hidden;
}

.alert-settings__container {
    display: -webkit-flex;
    display: flex;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

/* TODO: Fix the problem with the navbar height */
.alert-settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
    z-index: 999;
}

.alert-settings__menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: 100%;
    height: -webkit-max-content;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.alert-settings__menu-header {
    display: -webkit-flex;
    display: flex;
    margin-top: 50px;
    margin-left: 3%;
    padding-bottom: 10px;
}

.alert-settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.alert-settings__header-title {
    margin-top: -2px;
    margin-left: 1%;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 200px;
}

.alert-settings__user-profile {
    margin-left: 500px;
}

/* .alert-settings__menu-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
} */

.alert-settings__title {
    display: -webkit-flex;
    display: flex;

    margin-top: 10px;
    margin-left: 6%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__title-dot {
    margin-right: 3px;

    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__text {
    margin-top: 5px;
    margin-left: 8%;

    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.alert-settings__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 19%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: -webkit-max-content;
    max-width: max-content;
    max-height: 11.5px;
}

.alert-settings__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: white;
    border: 1px solid #505050;
}

.alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #1d2934;
    border: 1px solid #505050;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__alerts-menu-container {
    padding-top: 50px;
    padding-bottom: 80px;
    margin-left: 4%;

    width: 100%;
    height: 100%;
}

.alert-settings__alerts__crop-menu-container {
    padding-left: 50px;
    margin: 0px;
    width: 700px;

    display: -webkit-flex;

    display: flex;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;

    list-style: none;
}

.alert-settings__alerts__crop-menu {
    padding: 12px;

    width: -webkit-max-content;

    width: max-content;

    font-weight: 400;
}

.react-tabs__tab--selected .alert-settings__alerts__crop-menu {
    display: -webkit-flex;
    display: flex;

    font-weight: 600;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.react-tabs__tab--selected .alert-settings__alert__crop-menu-icon {
    display: block;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-left: 8px;
    margin-top: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.alert-settings__alerts__crop-menu:hover {
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
    cursor: pointer;
}

.alert-settings__alerts__crop-menu--selected:hover {
    cursor: pointer;
}

.alert-settings__alerts__crop-menu-title {
    font-size: 13px;
}

.alert-settings__alert__crop-menu-icon {
    display: none;
}

/* TODO: Check why the Tab List creates an extra space above when it has more than 1 variety */
.alert-settings__alerts__variety-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    list-style: none;
    overflow-x: scroll;
}

.alert-settings__alerts__variety-button {
    height: 24px;
    width: -webkit-max-content;
    width: max-content;
    background-color: rgba(28, 150, 144, 0.3);
    border: none;
    border-radius: 20px;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-button {
    background-color: #1c9690;
}

.alert-settings__alerts__variety-title {
    padding: 2px 8px;
    width: -webkit-max-content;
    width: max-content;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    color: #1d2934;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-title {
    font-weight: 700;
    color: white;
}

.alert-settings__alerts-container {
    position: relative;

    padding: 0 10px 20px 10px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;

    width: 750px;
    height: -webkit-max-content;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.alert {
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    font-family: "Montserrat";
}

.hidden {
    display: none;
}

.opacity {
    opacity: 50%;
}

.alert__container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 16px 0px;

    width: 750px;
    height: -webkit-max-content;
    height: max-content;

    border-radius: 10px;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.alert__header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    height: 20px;
}

.alert__header__plus-icon {
    height: 24px;
    width: 24px;
    margin-left: 15px;
    cursor: pointer;
}

.disabled {
    cursor: auto;
    opacity: 50%;
}

.alert__header__title {
    margin-left: 10px;
    /* margin-right: auto; */
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.alert__header__custom-icon {
    margin-left: 10px;
    margin-top: 2px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    width: 17px;
    height: 17px;

    background-color: #1d2934;

    border-radius: 50%;
}

.alert__header__alert-count {
    margin-right: auto;
    margin-left: 10px;
    font-size: 14px;
}

.alert__header__arrow-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);

    margin-left: auto;
    margin-right: 50px;

    height: 15px;
    width: 15px;
}

.alert__header__arrow-icon.upside {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.alert__header__arrow-icon:hover {
    cursor: pointer;
}

.alert__content {
    padding: 10px;
}

.alert__content__header {
    margin-left: 30px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.alert__content__title-dot {
    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-input {
    height: 10px;
    width: 50%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-input::-webkit-input-placeholder {
    color: lightgray;
}

.alert__content__title-input:-ms-input-placeholder {
    color: lightgray;
}

.alert__content__title-input::placeholder {
    color: lightgray;
}

.alert__content__title-input:first-line {
    display: inline-block;
}

.alert__content__title {
    margin-left: 10px;

    width: 60%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert__content__title-error {
    font-size: 12px;
    color: red;
    position: absolute;
    top: 24px;
    left: 14px;
}

.alert__content__slider-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.alert__content__slider-label {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    color: rgba(80, 80, 80, 0.7);
}

.alert__content__slider-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
}

.alert__content__slider-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* .alert__content__slider-switch input:hover {
    cursor: pointer;
} */

.alert__content__slider-round {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939393;
    border-radius: 34px;
    transition: 0.4s;
    cursor: pointer;
}

.disabled .alert__content__slider-round {
    cursor: auto;
}

.alert__content__slider-round:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input:checked + .alert__content__slider-round {
    background: linear-gradient(180deg, #22c11f 0%, #03b800 100%);
}

input:checked + .alert__content__slider-round:before {
    -webkit-transform: translateX(16px);
    transform: translateX(16px);
}

.alert__content__conditions {
    padding: 20px;
    padding-left: 40px;
}

[class^="alert__content__conditions__options-"] {
    margin-bottom: 5px;

    display: -webkit-flex;

    display: flex;

    font-family: "Roboto";
    font-size: 13px;
    color: #505050;
}

[class^="alert__content__conditions__options-"] strong {
    color: #1d2934;
}

.alert__content__conditions__options-m-margin {
    margin-left: 20px;
}

.alert__content__conditions__options-l-margin {
    margin-top: 25px;
    margin-left: 40px;
}

.alert__content__conditions__container {
    max-width: 75px;
}

.alert__content__conditions__input {
    margin-right: 8px;
    margin-left: 8px;

    height: 16px;
    width: 50px;

    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #2c2c2c;

    background-color: white;
    border: 1px solid rgba(130, 130, 130, 0.5);
    border-radius: 5px;
}

.alert__content__conditions__input::-webkit-input-placeholder {
    color: lightgray;
}

.alert__content__conditions__input:-ms-input-placeholder {
    color: lightgray;
}

.alert__content__conditions__input::placeholder {
    color: lightgray;
}

.alert__content__conditions__input:disabled {
    background-color: #e9ebef;
}

.alert__content__conditions__input .alert__content__conditions__input:disabled {
    color: #2c2c2c;
    background-color: #e2e2e2;
}

.alert__content__conditions__input-error {
    color: red;
    margin-left: -100%;
    min-width: 400%;
    font-family: "Montserrat";
    font-size: 12px;
}

.alert__content__edit-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    grid-gap: 20px;
    gap: 20px;

    margin-right: 30%;
}

.alert__content__edit-icon {
    height: 13px;
    width: 13px;
}

.alert__content__edit-icon:hover {
    cursor: pointer;
}

.alert__content__trash-icon {
    height: 22px;
    width: 22px;
}

.alert__content__trash-icon:hover {
    cursor: pointer;
}

.alert__content__button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    grid-gap: 20px;
    gap: 20px;

    margin-right: 20px;
}

.alert__content__cancel-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #f47920;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__cancel-button:hover {
    cursor: pointer;
}

.alert__content__save-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__save-button:hover {
    cursor: pointer;
}

.alert__content__divider {
    height: 20px;
}

.alert__content__divider::after {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-bottom: 1px solid rgba(29, 41, 52, 0.5);
}

.chart-actions__options-container {
    display: -webkit-flex;
    display: flex;

    -webkit-justify-content: space-between;

            justify-content: space-between;
}

.chart-action__header-block-option {
    margin: 0 10px;
    width: 100% !important;
}

.chart-actions__dropdown-button {
    margin-left: 1em;
    margin-right: 1em;
}

.chart-actions__field-properties {
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    background: linear-gradient(131.47deg, #f3f9ff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
    border-radius: 10px;
}

.chart-action--media {
    display: inline;
    display: initial;
}

.chart-action__icon {
    height: 24px;
    width: 24px;
}

/* TODO: Work on the className of the elements of ChartActions */

@media screen and (min-width: 600px) and (max-width: 1100px) {
    .chart-actions__options-container {
        padding-top: 20px;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .chart-action__header-block-option {
        width: 130px !important;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1em;
    }

    .chart-actions__field-properties {
        height: 230px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .chart-actions__field-properties .field-properties__info {
        font-size: 13px;
    }

    .chart-actions__field-properties .mapboxgl-map {
        margin-top: -80px !important;
        height: 100px !important;
        width: 135px !important;
    }

    /* .chart-actions__field-properties .mapboxgl-canvas {
        height: 50px !important;
    } */
}

@media screen and (min-width: 950px) and (max-width: 1100px) {
    .chart-action__header-block-option {
        width: 165px !important;
    }
}

.d3-tip {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.4;
    padding: 20px;
    pointer-events: none !important;
    color: #203d5d;
    box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
    background-color: #fff;
    border-radius: 4px;
}

/* Creates a small triangle extender for the tooltip */

.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: #fff;
    position: absolute;
    pointer-events: none;
}

/* Northward tooltips */

.d3-tip.n:after {
    content: "▼";
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

/* Eastward tooltips */

.d3-tip.e:after {
    content: "◀";
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
}

/* Southward tooltips */

.d3-tip.s:after {
    content: "▲";
    margin: 0 0 1px 0;
    top: -8px;
    left: 0;
    text-align: center;
}

/* Westward tooltips */

.d3-tip.w:after {
    content: "▶";
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
}
.d3-line-area-chart-container {
    width: calc(100%);
}
.chart-container-element {
    display: inline-block;
    width: calc(100% - 124px);
}

.chart-specs-container {
    display: inline-block;
    width: 120px;
    vertical-align: top;
}

.chart-specs-container>div {
    padding-left: -3px;
    margin-left: 3px;
    background-color: white;
    min-height: 100%;
}

.weather-model {
    padding-left: 2em;
    padding-right: 4em;
    padding-bottom: 2em;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.map-component {
    margin-bottom: 3em;
    width: 100%;
    height: 750px;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px #c0d2e4;
}

.map-component__container {
    width: 100%;
    height: 750px;
    border-radius: 10px;
}

.ndvigraph {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.ndvigraph-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 1em;
}

.ndvigraph-content .chartjs-render-monitor {
    /* Probably there's a better way to do this, thing is the canvas doesn't seem to inherit size from parent */
    height: 100% !important;
}

.gddgraph {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.gddgraph_content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 1em;
}

.satellite-model {
    height: 100%;
    /* Solve this issue with the width */
    width: 95%;
    box-sizing: border-box;
    overflow: hidden;
}

.satellite-model__container {
    display: grid;
    grid-template-columns: 67% 23%;
    grid-gap: 2em;
    gap: 2em;
    padding-top: 2em;
    padding-left: 2em;
    padding-bottom: 2em;
    height: 100%;
    width: 100%;
}

.satellite-model__graphs-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    height: 100%;
    width: 100%;
}

.satellite-model__graph-item {
}

.satellite-model__ndvi-graph {
}

.satellite-model__right-container__content {
    height: 100%;
    width: 100%;
}

@-webkit-keyframes opacityOnAndOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes opacityOnAndOff {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.message {
    -webkit-animation: opacityOnAndOff 2.5s infinite;
            animation: opacityOnAndOff 2.5s infinite;
}

.planting-tool {
    padding-left: 2em;
    padding-bottom: 2em;
    padding-top: 1em;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.planting-tool .MuiCard-root{
    background:#FFFFFF  !important;
}

.cl-button {
    color: rgb(28, 150, 144) !important;
}

.cl-button {
    background: #f3f7fb !important;
    box-shadow: 0px 4px 10px #c0d2e4;
    border: 1px solid #1c9690 !important;
    color: #1c9690;
    border-radius: 11px !important;
    text-transform: capitalize !important;
    padding: 3px 15px !important;
    letter-spacing: normal !important;
    letter-spacing: initial !important;
}

.cl-button:disabled {
    color: rgb(29 41 52 / 50%) !important;
    border: 1px solid rgb(29 41 52 / 50%) !important;
    cursor: not-allowed !important;
}

.planting-tool__error-message {
    color: red;
    font-family: "Montserrat";
    font-size: 11px;
}

.d3-tip {
    z-index: 99999;
}

.chart-preload-container.with-message {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.chart-preload-container.with-message .message {
    margin-top: 12px;
}

.planting-tool__date-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    /* background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%); */
}

.planting-tool_date-option {
    margin: 5px;
}

.planting-tool__risk-details-item {
    display: inline-block;
    padding: 10px;
    width: 50%;
}

.planting-tool__risk-factors__title {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 16px;
}

.planting-tool__risk-factors__text {
    margin-top: -5px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
}

.planting-tool__chart .svg-chart-container {
    background: transparent !important;
}

@media screen and (max-width: 1100px) {
    .planting-tool {
        padding-right: 70px;
    }
    .planting-tool__date-container {
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin: 0 30px;
    }
    .planting-tool__risk-details-item {
        padding: 15px;
    }
}

.d3-single-row-bar-chart-container {
    width: 100%;
}
.risk-factors__settings-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.risk-advanced-settings {
    color: #3984bc;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    text-align: end;
}

.risk-factors__risks-container {
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.risk-factors__risks {
    /* margin-top: 10px; */
    margin-right: auto;
    display: -webkit-flex;
    display: flex;
}

.cancel-button {
    background: #f3f7fb !important;
    box-shadow: 0px 4px 10px #c0d2e4;
    border: 1px solid rgb(211, 56, 56) !important;
    color: rgb(211, 56, 56) !important;
    border-radius: 11px !important;
    text-transform: capitalize !important;
    padding: 3px 8px !important;
    letter-spacing: normal !important;
    letter-spacing: initial !important;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 12px 0px;
    background-color: #50505073;
}

.risk-advanced-settings__buttons-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.risk-factors__color-block {
    display: inline-block;
    margin-right: 7px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
}

.risk-factors__risk-name {
    margin-bottom: 6px;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 700;
}

.risk-factors__description {
    font-family: "Roboto";
    font-size: 12px;
}

@media screen and (max-width: 1100px) {
    .risk-factors__risks-container {
        padding: 0 10px;
        -webkit-flex-direction: row;
                flex-direction: row;

        -webkit-justify-content: center;

                justify-content: center;
        /* justify-items: center; */
        place-items: center;
        -webkit-align-content: center;
                align-content: center;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        grid-gap: 15px 20px;
        gap: 15px 20px;
    }

    .risk-factors__settings-container {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
    }

    .risk-factors__risks {
        margin-right: 0;
        padding: 5px;
        -webkit-flex-basis: 29%;
                flex-basis: 29%;
    }

    .risk-factors__color-block {
        margin-bottom: -20px;
        width: 30px;
        height: 30px;
    }

    .risk-factors__description {
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


html,
body,
#root {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
    font-family: "Montserrat-Bold";
    src: url(/static/media/Montserrat-Bold.ade91f47.ttf);
}

@font-face {
    font-family: "Montserrat";
    src: url(/static/media/Montserrat-Regular.ee653992.ttf);
}

:root {
    /* shadows */
    --shadow-generic: 0px 4px 10px #c0d2e4;
    --shadow-positive: 0px 4px 10px rgba(116, 167, 49, 0.44);
    --shadow-medium: 0px 4px 10px rgba(255, 154, 61, 0.44);
    --shadow-negative: 0px 4px 10px rgba(255, 61, 61, 0.44);

    /* colors */
    --color-inactive-icon: #8a9198;
    --color-active-icon: #384e63;
    --color-normal-status: #74a731;
    --color-negative-alert: #ff3d3d;
    --color-medium-alert: #ffbd3d;
    --color-positive-alert: #22c11f;
    --color-climate-green: #1f9992;
    --color-general-grey: #404040;
    --color-orange-climate: #f27930;
    --color-over-menu-bar: #aae0df;
    --color-dark-blue: #1d2934;
    --color-red-graph-growing: #da3849;
    --color-yellow-graph-growing: #e5a339;
    --color-yellow-wind: #faaa11;
    --color-blue-precipitation: #2288c1;

    /* gradients */
}

/* text */
.title-section-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.card-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
}

.title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.regular-text,
.standard-text-card {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.table-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 10px;
}

.table-title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 12px;
}

.side-menu-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.side-menu-date-text {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.side-menu-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.line {
    fill: none;
    stroke: steelblue;
    stroke-width: 2px;
}

.area {
    fill: lightsteelblue;
}

header.mui-fixed {
    z-index: 9998 !important;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.chart-preload-container div{
    color: #1C9690 !important;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}


