.table-label-item {
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 55px;
    height: 2em;
    margin-right: 1em;
    padding-left: 0.7em;
    padding-right: 0.7em;

    background-color: #ddd;
    border-radius: 1em;

    color: #fff;
    font-size: 0.8em;
    white-space: nowrap;
}

.table-label-item__text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    text-align: center;
}

.table-label-item:last-child {
    margin-right: initial;
}

.table-item-labels__item__delete {
    display: none;

    position: absolute;
    top: -10px;
    right: -10px;

    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    background: #4e4e4e;

    width: 15px;
    height: 15px;
    padding: 1px;
    border-radius: 7px;
}

.table-label-item:hover .table-item-labels__item__delete {
    display: block;
}
