.dashboard * {
    box-sizing: border-box;
}

.dashboard {
    margin: 0;
    padding: 0;
    background: #f4f6f8;
    min-height: 100%;
    height: max-content;
    /* height: 100%; */
    width: 100%;
    display: flex;
    font-family: "Montserrat";
}

.dashboard__sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    /* height: 100%; */
    justify-content: space-between;
}

.dashboard__logo {
    padding: 20px;
    width: 120px;
}

.dashboard__about {
    margin-bottom: 50px;
    margin-left: 40px;
    color: rgba(29, 41, 52, 0.5);
}

.dashboard__about:hover {
    cursor: pointer;
}

.dashboard__header {
    width: 900px;
    margin: 0 auto;
    padding-top: 5em;
    position: relative;
    padding-bottom: 10px;

    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
}

.dashboard__header__title {
    margin: 0;
    padding-left: 40px;

    display: flex;
    align-items: center;

    font-size: 20px;
    font-weight: 600;
}

.dashboard__header__user-info {
    display: flex;
    align-items: flex-start;
}

.dashboard__header__user-info__text {
    padding-left: 8px;
    padding-right: 40px;
}

.dashboard__header__user-info__text__name {
    margin: 0;

    font-size: 10px;
    font-weight: 600;
}
.dashboard__header__user-info__text__logout {
    margin: 0;

    border: none;
    background: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;

    font-size: 8px;
    font-weight: 400;
}

.dashboard__header__user-info__image {
    width: 32px;
    height: 32px;
    border-radius: 15px;
}

/* @media screen and (min-width: 700px) and (max-width: 1000px) {
    .dashboard__header {
        width: 700px;
    }
} */

@media screen and (max-width: 1100px) {
    .dashboard {
        flex-direction: column;
    }
    .dashboard__header {
        width: 900px;
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        padding-top: 30px;
    }

    .dashboard__logo {
        padding: 10px 0 0 0;
    }
}

@media screen and (max-width: 900px) {
    .dashboard__header {
        width: 700px;
    }
}

@media screen and (min-width: 1440px) {
    .dashboard__header {
        width: 1100px;
    }
}

@media screen and (min-width: 1920px) {
    .dashboard__header {
        width: 1350px;
    }
}

@media screen and (min-width: 2300px) {
    .dashboard__header {
        width: 1600px;
    }
}
