.navbar-option__menu {
    margin-top: 6px;
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.navbar-option__menu::after {
    display: block;
    margin: 4px auto 0px;
    width: 85%;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    content: "";
}

.navbar-option__menu__tool {
    padding: 2px 0 6px 20px;

    font-family: "Roboto";
    font-size: 11px;
    font-weight: 700;
    color: rgba(29, 41, 52, 0.5);

    text-decoration: none;
}

a.navbar-option__menu__tool:hover {
    background-color: rgba(38, 50, 56, 0.04);
}

.navbar-option__menu__sub-tool {
    /* margin-left: 15%; */
    padding: 0;
    margin: 0;
    font-family: "Roboto";
    text-transform: none;
    font-size: 11px;
    font-weight: 400;
    color: rgba(29, 41, 52, 0.5);
    border: none;
}

.navbar-option__menu__sub-tool--active {
    font-weight: 700;
    color: rgba(29, 41, 52);
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar-option__menu__tool {
        padding: 10px 0 10px 20px;
        font-size: 16px;
    }

    .navbar-option__menu__sub-tool {
        margin-top: 5px !important;
        font-size: 15px !important;
    }

    .navbar-option__menu::after {
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar-option__menu__tool {
        padding: 12px 0 12px 28px;
        font-size: 18px;
    }

    .navbar-option__menu__sub-tool {
        margin-top: 8px !important;
        font-size: 17px !important;
    }

    .navbar-option__menu::after {
        margin-top: 12px;
        margin-bottom: 8px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar-option__menu {
        margin-left: 100px;
    }

    .navbar-option__menu::after {
        border-bottom: none;
    }

    .navbar-option__menu__tool {
        padding: 30px 0;
        font-size: 22px;
        color: #1d2934;
    }

    .navbar-option__menu__sub-tool {
        font-size: 22px;
    }
}
