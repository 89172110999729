.about-modal__image {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 35px;
}

.about-modal__text {
    font-size: 16em;
    font-weight: 500;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;

    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    width: 270px;

    text-align: center;
}

.about-modal__company-logo-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 300px;
}

.about-modal__company-logo {
    margin: auto;
    height: 25px;
    width: 60px;
}

.about-modal__company-logo-image {
    /* margin: auto 20px; */
    width: 100%;
    height: 100%;
}

.about-modal__company-logo__ecmwf {
    margin: auto;

    height: 16px;
    width: 70px;
}

.about-modal__company-logo__noaa {
    /* margin: auto 20px; */
    margin-left: none;
    margin-right: none;
    height: 55px;
    width: 90px;
}

.about-modal__company-logo__open-weather {
    /* margin: auto 20px; */
    /* margin-left: none;
    margin-right: none; */
    height: 30px;
    width: 70px;
}
