.sign-in-side {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-family: "Montserrat";
}

.sign-in-side__main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sign-in-side__app-bar-spacer {
    min-height: 64px;
}

.sign-in-side__grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    gap: 0;
}

.sign-in-side__grid-item__image {
    grid-column: 1 / 2;
    background-image: url(/images/sign_in.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.sign-in-side__grid-item__content {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #1d2934;
    box-shadow: -1px 0 10px black;
}

.sign-in-side__logo {
    margin-top: 126px;
    min-width: 158px;
    height: 57.5px;
}

.sign-in-side__login-text {
    margin-top: 5px;
    margin-bottom: 18px;
    font-size: 19px;
    font-weight: 600;
    color: white;
}

.sign-in-side__form {
    position: relative;
    width: 100%;
    height: max-content;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.sign-in-side__text-input {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-bottom: 2px;
    width: 70%;
    font-size: 9.5px;
    line-height: 1.6;
    color: white;
    background-color: #1d2934;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}

.sign-in-side__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 15%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: max-content;
    max-height: 11.5px;
}

.sign-in-side__checkbox {
    position: relative;
    display: inline-block;
    margin-left: 1px;
    margin-right: 5px;
    margin-top: 5px;
    width: 12px;
    height: 12px;
}

.sign-in-side__checkbox-input {
    width: 12px;
    height: 12px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;
}

.sign-in-side__checkbox-input:hover {
    cursor: pointer;
}

.sign-in-side__checkbox-label {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.sign-in-side__checkbox-input:checked + .sign-in-side__checkbox-label {
    border: none;
    background-color: #1f9992;
}

.sign-in-side__checkbox-text {
    position: absolute;
    padding-left: 20px;

    padding-top: 2px;
    width: 200px;
    font-size: 10px;
    color: white;
}

.sign-in-side__log-button {
    margin-top: 30px;
    margin-bottom: 7px;
    margin-left: auto;
    margin-right: auto;
    width: 138px;
    height: 22px;
    font-size: 9.5px;
    font-weight: 600;
    color: white;
    background-color: #1f9992;
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.sign-in-side__log-button:hover {
    cursor: pointer;
}

.sign-in-side__divider-container {
    margin-top: 30px;
    margin-bottom: 7px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.sign-in-side__divider-line {
    margin-left: 3%;
    margin-right: 3%;
    width: 45%;
}

.sign-in-side__divider-line::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.sign-in-side__divider-text {
    width: 12%;
    text-align: center;
    font-size: 9.5px;
    font-weight: 400;
    color: white;
}

/* .sign-in-side__google-sign-in {
    margin-left: 34%;
    margin-right: 33%;
    margin-top: 15px;
    height: 30px;
    width: 140px;
} */

.sign-in-side__google-sign-in:hover {
    cursor: pointer;
}

.sign-in-side__sign-up {
    margin-top: 15px;
    align-self: center;
    width: max-content;
    color: white;
    font-size: 9.5px;
    text-decoration: none;
}

.sign-in-side__sign-up b {
    font-weight: 600;
}

.sign-in-side__restore-password {
    align-self: center;
    margin-top: 5px;
    margin-bottom: 100px;
    height: max-content;
    width: max-content;
    font-size: 9.5px;
    color: white;
    text-decoration: none;
}

.sign-in-side__copyright {
    margin-bottom: 10px;
    height: 20px;
    color: white;
}

@media screen and (min-width: 1360px) {
    .sign-in-side__logo {
        margin-top: 105px;
        width: 224px;
        min-height: 80.5px;
    }

    .sign-in-side__login-text {
        margin-top: 8px;
        font-size: 24px;
        font-weight: 600;
    }

    .sign-in-side__text-input {
        margin-top: 18px;
        margin-left: 14%;
        margin-right: 14%;
        padding-left: 16px;
        width: 72%;
        font-size: 13px;
        line-height: 1.8;
    }

    .sign-in-side__checkbox-container {
        margin-top: 17px;
        margin-left: 15%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /* gap: 20px; */
        width: 200px;
    }

    .sign-in-side__checkmark {
        margin-top: 4px;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #1d2934;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .sign-in-side__checkbox-input {
        width: 14px;
        height: 14px;
    }

    .sign-in-side__checkbox-label {
        width: 14px;
        height: 14px;
    }

    .sign-in-side__checkbox-text {
        margin-left: 5px;
        margin-top: -2px;
        width: 200px;
        font-size: 12px;
    }

    .sign-in-side__checkmark--checked {
        margin-top: 4px;
        width: 14px;
        height: 14px;
    }

    .sign-in-side__checkmark--checked::after {
        left: 3px;
        bottom: 0.5px;

        width: 5px;
        height: 11px;

        border-width: 0 3px 3px 0;
    }

    .sign-in-side__log-button {
        margin-top: 40px;
        margin-bottom: 12px;
        width: 210px;
        height: 35px;
        font-size: 13px;
    }

    .sign-in-side__divider-container {
        margin-top: 35px;
        margin-bottom: 12px;
    }

    .sign-in-side__divider-text {
        font-size: 13px;
    }

    .sign-in-side__sign-up {
        margin-top: 20px;

        font-size: 13px;
    }

    .sign-in-side__restore-password {
        margin-top: 10px;
        margin-bottom: 80px;
        font-size: 13px;
    }
}

@media screen and (min-width: 1919px) {
    .sign-in-side__grid {
        grid-template-columns: 65% 35%;
    }

    .sign-in-side__logo {
        margin-top: 105px;
        width: 270px;
        min-height: 87.5px;
    }

    .sign-in-side__login-text {
        margin-top: 16px;
        margin-bottom: 100px;
        font-size: 31px;
        font-weight: 600;
    }

    .sign-in-side__text-input {
        margin-top: 25px;
        margin-left: 14%;
        margin-right: 14%;
        padding-left: 24px;
        width: 67%;
        font-size: 18px;
        line-height: 1.8;
    }

    .sign-in-side__checkbox-container {
        margin-top: 15px;
        margin-left: 15%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: max-content;
    }

    .sign-in-side__checkmark {
        margin-top: 4px;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #1d2934;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .sign-in-side__checkbox {
        margin-left: 1px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
    }

    .sign-in-side__checkbox-input {
        width: 16px;
        height: 16px;
    }

    .sign-in-side__checkbox-label {
        width: 16px;
        height: 16px;
    }

    .sign-in-side__checkbox-text {
        position: absolute;
        margin-left: 18px;
        margin-top: 0;
        width: 200px;
        font-size: 14px;
    }

    .sign-in-side__checkmark--checked {
        margin-top: 4px;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #1f9992;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .sign-in-side__checkmark--checked::after {
        left: 3px;
        bottom: 0.5px;

        width: 5px;
        height: 11px;

        border-width: 0 3px 3px 0;
    }

    .sign-in-side__log-button {
        margin-top: 60px;
        margin-bottom: 20px;
        width: 210px;
        height: 30px;
        font-size: 14px;
    }

    .sign-in-side__divider-text {
        font-size: 14px;
    }

    .sign-in-side__sign-up {
        margin-top: 30px;

        font-size: 14px;
    }

    .sign-in-side__restore-password {
        margin-top: 15px;
        margin-bottom: 200px;
        font-size: 14px;
    }

    .sign-in-side__copyright {
        margin-bottom: 10px;
        height: 20px;
        color: white;
    }
}

@media screen and (max-width: 1200px) {
    .sign-in-side__grid {
        grid-template-columns: 55% 45%;
    }

    .sign-in-side__logo {
        margin-top: 50px;

        height: 100px;
    }

    .sign-in-side__login-text {
        margin-top: 30px;
        font-size: 30px;
    }

    .sign-in-side__form {
        margin-top: 0;
        margin-bottom: auto;
    }

    .sign-in-side__text-input {
        margin-left: auto;
        margin-right: auto;
        width: 83%;
        font-size: 16px;
        line-height: 2.5;
    }

    .sign-in-side__checkbox-container {
        margin-top: 2em;
        margin-left: 4em;
    }

    .sign-in-side__checkbox {
        width: 22px;
        height: 22px;
    }

    .sign-in-side__checkbox-label {
        width: 22px;
        height: 22px;
        border: 2px solid rgba(255, 255, 255, 0.5);
    }

    .sign-in-side__checkbox-text {
        padding-left: 35px;
        padding-top: 8px;
        font-size: 12px;
    }

    .sign-in-side__log-button {
        margin-top: 50px;
        height: 40px;
        width: 200px;
        font-size: 18px;
    }

    .sign-in-side__divider-text {
        font-size: 18px;
    }

    .sign-in-side__sign-up {
        margin-top: 30px;
        font-size: 18px;
    }

    .sign-in-side__restore-password {
        margin-top: 30px;
        font-size: 18px;
    }

    .sign-in-side__copyright {
        margin-top: auto;
        margin-bottom: 40px;
        width: 180px;
        text-align: center;
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    .sign-in-side__grid {
        grid-template-columns: 1fr 500px 1fr;
        grid-template-rows: 1fr 850px 1fr;
    }

    .sign-in-side__grid-item__image {
        grid-column: 1 / 4;
        grid-row: 1 / 4;
        width: 100%;
        height: 100%;
    }

    .sign-in-side__grid-item__content {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-radius: 20px;
    }

    .sign-in-side__logo {
        margin-top: 70px;
        /* margin-bottom: auto; */
        height: 110px;
    }

    .sign-in-side__login-text {
        margin-top: 1.5em;
        margin-bottom: 1em;
        font-size: 30px;
    }

    .sign-in-side__form {
        margin-top: 0;
        margin-bottom: auto;
    }

    .sign-in-side__text-input {
        margin-left: auto;
        margin-right: auto;
        width: 83%;
        font-size: 20px;
        line-height: 2.5;
    }

    .sign-in-side__checkbox-container {
        margin-top: 2em;
        margin-left: 4em;
    }

    .sign-in-side__checkbox {
        width: 24px;
        height: 24px;
    }

    .sign-in-side__checkbox-label {
        width: 24px;
        height: 24px;
        border: 2px solid rgba(255, 255, 255, 0.5);
    }

    .sign-in-side__checkbox-text {
        padding-left: 35px;
        padding-top: 8px;
        font-size: 14px;
    }

    .sign-in-side__log-button {
        margin-top: 50px;
        height: 45px;
        width: 220px;
        font-size: 20px;
    }

    .sign-in-side__divider-text {
        font-size: 20px;
    }

    .sign-in-side__sign-up {
        margin-top: 30px;
        font-size: 20px;
    }

    .sign-in-side__restore-password {
        margin-top: 30px;
        font-size: 20px;
    }

    .sign-in-side__copyright {
        margin-top: auto;
        margin-bottom: 20px;
        width: 300px;
    }
}
