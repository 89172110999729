.settings-layout {
    box-sizing: border-box;
    display: flex;
    overflow-y: hidden;

    width: 100%;
    min-height: 100vh;
    height: max-content;

    font-family: "Montserrat";

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.settings-layout__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
}

.settings-layout__content {
    margin-left: 15px;
}

@media screen and (max-width: 1100px) {
    .settings-layout {
        flex-direction: column;
    }
}