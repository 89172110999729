.MuiCardContent-root:last-child.field-properties__container {
    /* padding: 16px; */
}

.field-properties {
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    /* background: linear-gradient(131.47deg, #f3f9ff 7.84%, #eff2f6 93.19%); */
    background-color: white;
    box-shadow: 0px 4px 10px #c0d2e4;
    border-radius: 10px;
}

.field-properties__mapbox {
    height: 20px;
    width: 100px;
}

.field-properties__info {
    max-width: 100px;
    font-size: 11px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

/* .mapContainer.mapboxgl-map {
    width: 100%;
} */

/* Keep an eye on this one, as it overwrites the canvas style of mapboxgl */
.field-properties .field-properties__mapbox .mapboxgl-canvas {
    width: 100% !important;
    height: 90px !important;
    border-radius: 10px;
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .field-properties__info {
        font-size: 10px;
    }
}
