.chart-actions__options-container {
    display: flex;

    justify-content: space-between;
}

.chart-action__header-block-option {
    margin: 0 10px;
    width: 100% !important;
}

.chart-actions__dropdown-button {
    margin-left: 1em;
    margin-right: 1em;
}

.chart-actions__field-properties {
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    background: linear-gradient(131.47deg, #f3f9ff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
    border-radius: 10px;
}

.chart-action--media {
    display: initial;
}

.chart-action__icon {
    height: 24px;
    width: 24px;
}

/* TODO: Work on the className of the elements of ChartActions */

@media screen and (min-width: 600px) and (max-width: 1100px) {
    .chart-actions__options-container {
        padding-top: 20px;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .chart-action__header-block-option {
        width: 130px !important;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1em;
    }

    .chart-actions__field-properties {
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .chart-actions__field-properties .field-properties__info {
        font-size: 13px;
    }

    .chart-actions__field-properties .mapboxgl-map {
        margin-top: -80px !important;
        height: 100px !important;
        width: 135px !important;
    }

    /* .chart-actions__field-properties .mapboxgl-canvas {
        height: 50px !important;
    } */
}

@media screen and (min-width: 950px) and (max-width: 1100px) {
    .chart-action__header-block-option {
        width: 165px !important;
    }
}
