.table-item-editable__actions-container {
    display: flex;
    gap: 5px;
    padding: 0 5px;
}

[class*="table-item-editable__actions__button"] {
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table-item-editable__actions__button-check {
    width: 19px;
    height: 19px;
}

.table-item-editable__actions__icon {
    width: 100%;
    height: 100%;
}

.table-item-editable__actions__button-cancel {
    margin-top: 2px;
    width: 15px;
    height: 15px;
}

.table-item-editable__actions__button-delete {
    height: 20px;
    width: 20px;
}

.table-item-editable__actions__button:hover {
    background-color: #ddd;
}

@media screen and (min-width: 2300px) {
    .table-item-editable__actions__button-check {
        width: 26px;
        height: 26px;
    }

    .table-item-editable__actions__button-delete {
        height: 35px;
        width: 35px;
    }

    .table-item-editable__actions__button-cancel {
        margin-top: 2px;
        width: 20px;
        height: 20px;
    }
}
