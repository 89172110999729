.alert-icon {
    position: relative;
}

.alert-icon__indicator {
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff3d3d;

    position: absolute;
    bottom: 0%;
    right: -8%;

    width: 10px;
    height: 10px;

    font-size: 9px;
    color: #fff;
}

.thermometer-up-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thermometer-down-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heat-soil-icon {
    display: flex;
    flex-direction: column;
}

.heat-soil-icon__sun {
    margin-left: 3px;
    margin-top: 1px;
    height: 6px;
}

.heat-soil-icon__soil {
    margin-left: 1px;
    margin-bottom: 2px;
}

.cold-soil-icon {
    display: flex;
    flex-direction: column;
}

.cold-soil-icon__snow {
    margin-left: 1px;
    margin-top: 1px;
    height: 6px;
    width: 8px;
}

.cold-soil-icon__soil {
    margin-bottom: 2px;
}

@media screen and (min-width: 1440px) {
    .alert-icon__indicator {
        width: 11px;
        height: 11px;
        bottom: -1%;
        right: -7%;
    }
}

@media screen and (min-width: 1920px) {
    .alert-icon__indicator {
        width: 12px;
        height: 12px;
    }
}

@media screen and (min-width: 2300px) {
    .alert-icon__indicator {
        width: 15px;
        height: 15px;
        right: -5%;
    }
}
