.checkbox,
.checkbox > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.checkbox {
    position: relative;
    display: inline-block;
}

.checkbox__input {
    width: 14px;
    height: 14px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;

    cursor: pointer;
}

.checkbox__label {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #323232;

    background-color: none;

    transition: all 0.2s ease-out;
}

.checkbox__input:checked + .checkbox__label {
    border: none;
    background-color: #828282;
}

@media screen and (min-width: 1440px) {
    .checkbox__input {
        width: 16px;
        height: 16px;
    }

    .checkbox__label {
        width: 16px;
        height: 16px;
    }
}

@media screen and (min-width: 1920px) {
    .checkbox__input {
        width: 18px;
        height: 18px;
    }

    .checkbox__label {
        width: 18px;
        height: 18px;
    }
}

@media screen and (min-width: 2300px) {
    .checkbox__input {
        width: 22px;
        height: 22px;
    }

    .checkbox__label {
        width: 22px;
        height: 22px;
        border: 2px solid rgba(50, 50, 50, 0.9);
    }
}
