.label-select {
    position: absolute;
    z-index: 100;
    top: 20px;
    left: 20px;

    width: 130px;
    padding-top: 10px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 10px;
    border-radius: 10px;
    height: initial;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.label-select__text {
    padding-bottom: 14px;

    font-size: 10px;
    color: #505050;
}

.label-select__input-container {
    display: flex;

    border-bottom: 1px solid #505050;
}

.label-select__input-container:focus-within {
    border-bottom: 1px solid var(--color-climate-green);
}

/* TODO this class config is being used  mora than 3 times, change it for a component or a generic class */
.label-select__input-container__button {
    width: 20px;
    height: 20px;
    border-radius: 13px;
    padding: 3px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.label-select__input-container__button:hover {
    background-color: #ddd;
}

.label-select__input-container__input {
    display: block;
    width: 100%;

    font-size: 10px;

    border: none;
    background: none;
    outline: none;
}
