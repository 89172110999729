.settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
    z-index: 999;
}

.settings__menu {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.settings__menu-header {
    display: flex;
    margin-top: 50px;
    margin-left: 3%;
    padding-bottom: 10px;
}

.settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.settings__header-title {
    margin-top: -2px;
    margin-left: 1%;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 250px;
}

.settings__user-profile {
    margin-left: 500px;
}

/* .settings__menu-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
} */

.settings__title {
    display: flex;

    margin-top: 10px;
    margin-left: 6%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.settings__title-dot {
    margin-right: 3px;

    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}
