.navbar-fields-item {
    padding-top: 8px;
    padding-left: 10px;
    padding-bottom: 10px;

    cursor: pointer;
}

.navbar-fields-item:hover {
    background-color: #ddd;
}

/* .navbar-fields-item__image {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    height: 25px;
    width: 25px;
    border-radius: 20%;
} */

.navbar-fields-item__lot {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 500;

    padding-bottom: 5px;
}

.navbar-fields-item__crop {
    font-family: "Roboto";
    font-size: 8px;
    font-weight: 300;
    color: #1d2934;
}
/* 
@media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar-fields-item {
        padding: 13px 0 15px 13px;
    }

    .navbar-fields-item__lot {
        font-size: 16px;
    }

    .navbar-fields-item__crop {
        margin-top: 5px;
        font-size: 14px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar-fields-item {
        padding: 15px 0 18px 19px;
    }

    .navbar-fields-item__lot {
        font-size: 18px;
    }

    .navbar-fields-item__crop {
        margin-top: 8px;
        font-size: 16px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar-fields-item {
        padding: 20px 0 10px 20px;
    }

    .navbar-fields-item__lot {
        font-size: 20px;
    }

    .navbar-fields-item__crop {
        font-size: 18px;
    }
}
