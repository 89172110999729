.main {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.main__container {
    display: flex;
    /* background: red; */
    background: linear-gradient(131.47deg, #f3f9ff 7.84%, #eff2f6 93.19%);
}

.main__content {
    padding-left: 2em;
    padding-right: 4em;
    min-height: 100vh;
    height: 100%;
    width: 100%;
}

.main__header {
    display: flex;
    justify-content: space-between;
    margin-left: 2em;
    margin-right: 4em;
    margin-top: 3em;
}

.main__header__menu-icon {
    display: none;
}

.main__header__title-container {
    margin-right: auto;
    display: flex;
}

.main__header__title-return-icon {
    margin-top: 3px;
    width: 24px;
    height: 24px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.main__header__title-return-icon.clickable {
    cursor: pointer;
}

.main__header__title-model {
    margin-left: 10px;
    font-family: "Montserrat";
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
}

.main__header__title-icon {
    margin-top: 3px;
    width: 24px;
    height: 24px;
}

.main__header__title-field {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    color: #1d2934;
}

.main__header__options-container {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1100px) {
    .main {
        overflow-x: hidden;
    }

    .main__container {
        flex-direction: column;
    }
    
    .main__content {
        padding-right: 1em;
        padding-left: 1em;
    }

    .main__header {
    }

    .main__header__navbar {
        z-index: 999;
        width: 100%;        
    }

    .main__header__menu-icon {
        display: block;
        width: 28px;
        height: 28px;
    }

    /* .main__header__title-return-icon {
        display: none !important;
    } */

    .main__header {
        justify-content: space-between;
        margin-right: 3em;
    }
}

@media screen and (max-width: 800px) {
    .main__header__title-container {
        margin-left: auto;
    }
}
