.ui-modal__container {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    animation: ui-alert__animation-in 0.2s ease-out forwards;
}

.ui-modal__container.exit {
    animation: ui-alert__animation-out 0.2s ease-out forwards;
}

@keyframes ui-alert__animation-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes ui-alert__animation-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.ui-modal__background {
    width: 100%;
    height: 100%;

    position: absolute;
    background: #000;

    opacity: 0.5;
}

.ui-modal {
    background-color: #fff;

    padding-top: 40em;
    padding-bottom: 28em;
    padding-left: 40em;
    padding-right: 40em;

    max-width: 300px;

    border-radius: 10px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);

    font-size: 1px;

    position: relative;
    z-index: 10;
}

.ui-modal__close-button {
    width: 20px;
    height: 20px;

    background: #c9cdd1;
    border: none;
    outline: none;

    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px;
    margin: 0;

    cursor: pointer;

    position: absolute;
    top: 13px;
    right: 13px;
}

.ui-modal__title {
    font-size: 15em;
    font-weight: bold;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;

    margin-top: 10px;
    margin-bottom: 10px;

    text-align: center;
}

.ui-modal__description {
    font-size: 12px;
    font-weight: lighter;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;

    margin-bottom: 25px;

    text-align: center;
}

.ui-modal__buttons > button {
    display: block;
    border: none;
    outline: none;

    margin: 0 auto;
    cursor: pointer;

    font-family: "Montserrat";
}

.ui-modal__buttons__principal {
    height: 1.6em;
    border-radius: 0.8em;
    padding-left: 4em;
    padding-right: 4em;

    background-color: #f37920;

    color: #fff;

    margin-bottom: 0.8em !important;

    font-size: 12em;
    font-weight: 500;
}
.ui-modal__buttons__secondary {
    height: 1.6em;
    border-radius: 0.8em;
    padding-left: 2em;
    padding-right: 2em;

    background-color: rgba(0, 0, 0, 0.51);

    color: #fff;

    margin-bottom: 0.8em;

    font-size: 10em;
    font-weight: 500;
}
