.profile-settings {
    box-sizing: border-box;
    margin-left: 3%;
    width: 800px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px #c0d2e4;
}

.profile-settings__grid {
    display: grid;
    grid-template-columns: 30% 35% 35%;
}

.profile-settings__image-column {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.profile-settings__user-profile__icon {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 600;
    color: white;
    background-color: var(--color-orange-climate);
    border-radius: 50%;
}

.profile-settins__user-profile__icon.green {
    border: 2px solid var(--color-climate-green);
    color: var(--color-climate-green);
}

/* .profile-settings__image-column__title {
    margin-top: 20px;
    color: #1d2934;
    font-size: 15px;
    font-weight: 700;
} */

.profile-settings__image-column__image {
    margin-top: 30px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.profile-seetings__image-column__edit-icon-container {
    position: relative;
    z-index: 100;
    left: 32px;
    bottom: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: #1c9690;
    border-radius: 50%;
}

.profile-seetings__image-column__edit-icon-container:hover {
    cursor: pointer;
}

.profile-settings__info-column {
    grid-column: 2 / 3;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* height: 100%; */
}

.profile-settings__info-column__title-text {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__info-column__info-container {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    border-bottom: 1px solid #1c9690;
}

.profile-settings__info-column__info-container.error {
    border-bottom: 1px solid var(--color-negative-alert);
}

.profile-settings__info-column__info-text {
    margin-left: 2%;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    border: 0px;
    width: 75%;
}

.profile-settings__info-column__info-text:disabled {
    color: #7e7e7e;
    background: none;
}

.profile-settings__info-column__edit-icon-container {
    margin-right: 3%;
    height: 13px;
    width: 13px;
}

.profile-settings__info-column__edit-icon-container:hover {
    cursor: pointer;
}

.profile-settings__info-column__edit-confirmation-container {
    display: flex;
    gap: 5px;
    margin-left: -20px;
    height: 20px;
    width: max-content;
}

.profile-settings__info-column__edit-confirmation-container__confirm {
    width: 15px;
    height: 15px;
}

.profile-settings__info-column__edit-confirmation-container__cancel {
    width: 15px;
    height: 15px;
}

.profile-settings__info-column__info-container__error-message {
    position: absolute;
    font-size: 12px;
    color: red;
}

.profile-settings__info-column__info-password {
    margin-left: 2%;
    margin-bottom: 3px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    color: #505050;
}

.profile-settings__info-column__change-password-option {
    align-self: flex-end;
    margin-right: 20%;
    margin-top: 7px;
    font-size: 9px;
    color: #1c9690;
    text-decoration: none;
    cursor: pointer;
}

.profile-settings__unit-column {
    grid-column: 3 / 4;
    margin-top: 68px;
    display: flex;
    flex-direction: column;
}

.profile-settings__unit-column__title {
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__unit-column__text {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.profile-settings__button-container {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.profile-settings__button {
    display: flex;
    width: 90px;
    height: 22px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.profile-settings__button:hover {
    cursor: pointer;
}

.profile-settings__button.selected {
    display: flex;
    width: 90px;
    height: 22px;
    background-color: #1c9690;
    border-radius: 15px;
    border: 0;
}

.profile-settings__button-text {
    margin-top: 3px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.profile-settings__button.selected .profile-settings__button-text {
    margin-top: 3px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.profile-settings__button-icon {
    margin-top: 3px;
    margin-left: auto;
    margin-right: 2px;
    width: 14px;
    height: 14px;
    background-color: rgba(29, 41, 52, 0.25);
    /* border: 3px solid white; */
    border-radius: 50%;
}

.profile-settings__button.selected .profile-settings__button-icon {
    margin-top: 4px;
    margin-left: auto;
    margin-right: 2px;
    width: 8px;
    height: 8px;
    border: 3px solid white;
    border-radius: 50%;
}

.hidden {
    display: none;
}

@media screen and (max-width: 1100px) {
    .profile-settings {
        margin: 0;
        padding: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    .profile-settings__grid {
        grid-template-columns: 50% 50%;
        grid-template-rows: 70% 30%;
    }

    .profile-settings__user-profile__icon {
        width: 144px;
        height: 144px;
        font-size: 80px;
    }

    .profile-settings__info-column__title-text {
        font-size: 18px;
        font-weight: 400;
    }

    .profile-settings__info-column__info-text {
        font-size: 22px;
        font-weight: 600;
    }

    .profile-settings__info-column__info-text:disabled {
        color: #1d2934;
    }

    .profile-settings__info-column__info-password {
        font-size: 22px;
        color: #1d2934;
    }

    .profile-settings__info-column__change-password-option {
        font-size: 18px;
        font-weight: 500;
    }

    .profile-settings__unit-column {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    .profile-settings__unit-column__title {
        font-size: 22px;
    }

    .profile-settings__unit-column__text {
        margin-top: 20px;
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 400;
    }

    .profile-settings__button-container {
        margin-top: 20px;
    }

    .profile-settings__button {
        width: 120px;
        height: 28px;
    }

    .profile-settings__button.selected {
        width: 120px;
        height: 28px;
    }

    .profile-settings__button-text {
        margin-left: 10px;
        font-size: 14px;
    }

    .profile-settings__button.selected .profile-settings__button-text {
        font-size: 14px;
    }

    .profile-settings__button-icon {
        width: 18px;
        height: 18px;
    }

    .profile-settings__button.selected .profile-settings__button-icon {
        width: 12px;
        height: 12px;
        border: 3x solid white;
    }
}
