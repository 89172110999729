.navbar,
.navbar * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    width: 100%;
    height: 100%;
}

.navbar__container {
    /* see if this has to be set fit to 100% or another percentage */
    display: flex;
    flex-direction: column;
    min-width: 148px;
    height: 100%;
    box-shadow: var(--shadow-generic);
    background-color: white;
    overflow: hidden;
}

.navbar__header {
    min-height: 40px;
    height: 48px;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar__header-image {
    margin-left: 1%;
    width: 115px;
    height: 45px;
}

.navbar__exit {
    display: flex;
    flex-direction: row;
}

.navbar__exit:hover {
    cursor: pointer;
}

.navbar__exit-icon {
    margin-top: 5px;
    margin-left: 8%;
}

.navbar__exit-text {
    margin-top: 12px;
    margin-left: 4px;
    font-family: "Roboto";
    font-size: 8px;
}

.navbar__about {
    margin: auto auto 30px auto;
    font-size: 17px;
    color: rgba(29, 41, 52, 0.5);
}

.navbar__about:hover {
    cursor: pointer;
}

/* @media screen and (min-width: 700px) and (max-width: 900px) {
    .navbar__header {
        height: 60px;
    }
    .navbar__header-image {
        width: 135px;
        height: 65px;
    }
    .navbar__container {
        min-width: 175px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .navbar__header {
        height: 75px;
    }
    .navbar__header-image {
        width: 150px;
        height: 70px;
    }
    .navbar__container {
        min-width: 210px;
    }
} */

@media screen and (max-width: 1100px) {
    .navbar__container {
        min-width: 400px;
        background: linear-gradient(168.41deg, #f7fafe 0.43%, #eff4fa 105.08%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0 25px 25px 0;
    }

    .navbar__header {
        margin-top: 50px;
        border-bottom: none;
    }

    .navbar__header-image {
        width: 200px;
        height: 32px;
    }
}
