.user-profile {
    width: 50px;
    height: 40px;
    display: flex;
    box-sizing: border-box;
    font-family: "Montserrat";
}

.user-profile__visible {
    display: flex;
    cursor: pointer;
}

.user-profile__image {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.user-profile__deploy-icon--rotate {
    margin-top: 12px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.user-profile__deploy-icon--rotate:hover {
    cursor: pointer;
}

.user-profile__deploy-icon {
    transform: rotate(90deg);
    margin-top: 12px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.user-profile__deploy-icon:hover {
    cursor: pointer;
    /* background-color: #dde8f3; */
}

.user-profile__dropdown {
    right: 5px;
    top: 15px;
    position: relative;
    display: inline-block;
}

.user-profile__dropdown-menu {
    display: none;
    /* position: absolute;
    width: 95px;
    height: 140px;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px; */
}

.user-profile__dropdown-menu--open {
    z-index: 1000;
    display: block;
    position: absolute;
    width: 130px;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
}

.user-profile__dropdown-navigation-container {
    display: flex;
    flex-direction: column;
}

.user-profile__dropdown-navigation-container-edgar {
    display: flex;
    flex-direction: column;
}

.user-profile__dropdown-navigation-container::after {
    display: block;
    margin: 10px auto;
    width: 100%;
    border-bottom: 1px solid rgba(163, 177, 189, 0.5);
    content: "";
}

.user-profile__dropdown-option {
    margin-top: 2px;
    padding: 4px 0px 4px 4px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    color: #505050;
    text-decoration: none;
    cursor: pointer;
}

.user-profile__dropdown-option:hover {
    border-left: 3px solid #1F9992;
    padding-left: 1px;
    background-color: rgba(31, 153, 146, 0.14);
    border-radius: 0px 4px 4px 0px;
}

.user-profile__dropdown__user {
    margin-bottom: 6px;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.user-profile__dropdown-option--selected {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #1d2934;
}

.user-profile__logout-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 4px 0px 4px 4px;
}

.user-profile__logout-container:hover {
    border-left: 3px solid #1F9992;
    padding-left: 1px;
    background-color: rgba(31, 153, 146, 0.14);
    border-radius: 0px 4px 4px 0px;
}

.user-profile__logout-text {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    color: #505050;
}

.user-profile__logout-icon {
    transform: rotate(180deg);
    margin-top: 2px;
    margin-right: 6px;
    height: 15px;
    width: 15px;
}

.user-profile__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    /* border: 1px solid var(--color-inactive-icon); */
}

.user-profile__icon.orange {
    background-color: var(--color-orange-climate);
}

.user-profile__icon.green {
    background-color: var(--color-climate-green);
}

@media screen and (min-width: 700px) and (max-width: 1100px) {
    .user-profile__dropdown {
        top: 18px;
        right: 130px;
    }

    .user-profile__deploy-icon--rotate {
        transform: rotate(180deg);
    }

    .user-profile__dropdown-menu--open {
        width: 125px;
        height: max-content;
        background-color: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

    .user-profile__dropdown-option {
        margin-top: 15px;
        font-family: "Roboto";
        font-size: 14px;
        font-weight: 400;
        color: #505050;
        text-decoration: none;
    }

    .user-profile__dropdown__user {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .user-profile__dropdown-option--selected {
        margin-top: 15px;
        font-size: 14px;
    }

    .user-profile__logout-text {
        font-size: 14px;
    }

    .user-profile__logout-icon {
        margin-right: 10px;
        height: 16px !important;
        width: 16px !important;
    }
}
