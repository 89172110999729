.map-component {
    margin-bottom: 3em;
    width: 100%;
    height: 750px;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px #c0d2e4;
}

.map-component__container {
    width: 100%;
    height: 750px;
    border-radius: 10px;
}
