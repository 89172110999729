.chart-container-element {
    display: inline-block;
    width: calc(100% - 124px);
}

.chart-specs-container {
    display: inline-block;
    width: 120px;
    vertical-align: top;
}

.chart-specs-container>div {
    padding-left: -3px;
    margin-left: 3px;
    background-color: white;
    min-height: 100%;
}
