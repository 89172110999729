/* Table styles */

.table,
.table * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.table {
    display: flex;
    flex-direction: column;
}

.table__container {
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-generic);
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    border-radius: 20px;
    /* overflow: hidden; */
}

.table__main-header {
    width: 100%;
    padding: 10px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table__main-header__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.table__main-header__title__controls-left {
    display: flex;
    align-items: center;
    padding-right: 20px;
}
.table__main-header__title__controls-right {
    padding-left: 20px;
    border-left: 1px solid #ddd;

    position: absolute;

    left: 90px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.table__main-header__title__button-container {
    width: 40px;
}

.table__main-header__title__button {
    width: 26px;
    height: 26px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    cursor: pointer;

    transition: all 0.2s ease-out;
}
.table__main-header__title__button > div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.table__main-header__title__button:hover {
    background-color: #ddd;
}

.table__main-header__title__checkbox-container {
    width: 60px;
    padding-right: 25px;

    position: absolute;
    left: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-left: 15px; */
}

.table__main-header__title__controls-right__label-button {
    padding-right: 22px;
}

.table__main-header__title__select {
    display: block;
    border: none;
    background: #dee0e3;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;

    font-size: 10px;
    font-weight: 500;

    border-bottom: 2px solid #666;
    border-top-left-radius: 2px;
    border-top-right-radius: 4px;
    cursor: pointer;
}

.table__main-header__title__text {
    padding-left: 28px;

    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat";
}

.table__main-header__search-bar {
    width: 230px;

    margin-right: 40px;
    border-bottom: 1px solid #000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.table__main-header__search-bar__input {
    padding-left: 8px;
    padding-right: 8px;
    border: none;
    outline: none;
    background: none;

    font-size: 12px;

    flex: 1;
}

.table__main-header__search-bar__button {
    width: 26px;
    height: 26px;
    border-radius: 13px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-climate-green);

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table__main-header__search-bar__button:hover {
    background-color: #ddd;
}

/* TableHeader Component */
.table-header .table-header__icon {
    position: absolute;
    right: 0;
    font-size: 12px;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
}

.table-header.active {
    color: #333;
}

.table-header:hover .table-header__icon {
    opacity: 1;
}

.table-header.active .table-header__icon {
    opacity: 1;
}

/* table pagination */
.table__pagination {
    width: 50%;
    padding-top: 20px;
    padding-right: 20px;

    align-self: flex-end;
}

/* table alert */
.alert-dot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: #ffbd3d;
}

/* table shape file */
.shape-file {
    width: 16px;
    height: 16px;
    border-radius: 8px;

    background: #22c11f;

    font-size: 14px;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* table weather file */
.weather-file {
    font-size: 18px;
    color: #ffbd3d;
}

.table__loader {
    width: 100%;
    height: 2px;

    display: flex;
    justify-content: center;
}

.table__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 2px;
    background-color: var(--color-climate-green);

    animation: table__loader--animation 1s ease-out infinite;
}

.table__mapbox__container {
    max-height: 1000px;
    overflow: auto;
    transition: max-height 0.25s ease-out;
}

.table__mapbox__container.hide {
    max-height: 0;
}

@keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@media screen and (min-width: 1440px) {
    .table__main-header {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .table__main-header__search-bar {
        width: 280px;
    }

    .table__main-header__search-bar__input {
        font-size: 13px;
    }
    .table__main-header__title__button {
        width: 28px;
        height: 28px;
    }
}

@media screen and (min-width: 1920px) {
    .table__main-header__search-bar {
        width: 300px;
    }

    .table__main-header__search-bar__input {
        font-size: 14px;
    }

    .table__main-header__title__button {
        width: 30px;
        height: 30px;
    }
}

@media screen and (min-width: 2300px) {
    .table__main-header {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .table__main-header__search-bar {
        width: 400px;
    }

    .table__main-header__search-bar__input {
        font-size: 18px;
    }

    .table__main-header__title__button {
        height: 40px;
        width: 40px;
    }
}
