.alert-settings {
    box-sizing: border-box;

    width: 100vw;
    height: 100vh;

    font-family: "Montserrat";

    /* TODO: see why there's an overflow scroll beyond the container */
    overflow-x: hidden;
}

.alert-settings__container {
    display: flex;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

/* TODO: Fix the problem with the navbar height */
.alert-settings__container__navbar {
    box-shadow: 0px 4px 10px #c0d2e4;
    z-index: 999;
}

.alert-settings__menu {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
}

.alert-settings__menu-header {
    display: flex;
    margin-top: 50px;
    margin-left: 3%;
    padding-bottom: 10px;
}

.alert-settings__return-icon {
    width: 15px;
    height: 15px;
    color: white;
    background-color: black;
    border-radius: 50%;
}

.alert-settings__header-title {
    margin-top: -2px;
    margin-left: 1%;
    font-size: 24px;
    color: rgba(29, 41, 52, 0.5);
    width: 200px;
}

.alert-settings__user-profile {
    margin-left: 500px;
}

/* .alert-settings__menu-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
} */

.alert-settings__title {
    display: flex;

    margin-top: 10px;
    margin-left: 6%;

    font-size: 12px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__title-dot {
    margin-right: 3px;

    height: 15px;

    text-align: center;
    line-height: 0.3;
    font-size: 38px;
    font-weight: 600;
    color: #1d2934;
}

.alert-settings__text {
    margin-top: 5px;
    margin-left: 8%;

    font-size: 12px;
    font-weight: 500;
    color: #1d2934;
}

.alert-settings__checkbox-container {
    position: relative;
    margin-top: 9px;
    margin-left: 19%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: max-content;
    max-height: 11.5px;
}

.alert-settings__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: white;
    border: 1px solid #505050;
}

.alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark {
    margin-top: 4px;
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #1d2934;
    border: 1px solid #505050;
}

.alert-settings__checkbox:checked ~ .alert-settings__checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.alert-settings__alerts-menu-container {
    padding-top: 50px;
    padding-bottom: 80px;
    margin-left: 4%;

    width: 100%;
    height: 100%;
}

.alert-settings__alerts__crop-menu-container {
    padding-left: 50px;
    margin: 0px;
    width: 700px;

    display: flex;
    gap: 20px;
    overflow-x: scroll;

    list-style: none;
}

.alert-settings__alerts__crop-menu {
    padding: 12px;

    width: max-content;

    font-weight: 400;
}

.react-tabs__tab--selected .alert-settings__alerts__crop-menu {
    display: flex;

    font-weight: 600;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.react-tabs__tab--selected .alert-settings__alert__crop-menu-icon {
    display: block;
    transform: rotate(90deg);
    margin-left: 8px;
    margin-top: 5px;
    width: 10px;
    height: 10px;
    color: #4e4e4e;
}

.alert-settings__alerts__crop-menu:hover {
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
    cursor: pointer;
}

.alert-settings__alerts__crop-menu--selected:hover {
    cursor: pointer;
}

.alert-settings__alerts__crop-menu-title {
    font-size: 13px;
}

.alert-settings__alert__crop-menu-icon {
    display: none;
}

/* TODO: Check why the Tab List creates an extra space above when it has more than 1 variety */
.alert-settings__alerts__variety-container {
    display: flex;
    gap: 10px;
    list-style: none;
    overflow-x: scroll;
}

.alert-settings__alerts__variety-button {
    height: 24px;
    width: max-content;
    background-color: rgba(28, 150, 144, 0.3);
    border: none;
    border-radius: 20px;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-button {
    background-color: #1c9690;
}

.alert-settings__alerts__variety-title {
    padding: 2px 8px;
    width: max-content;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    color: #1d2934;
}

.react-tabs__tab--selected .alert-settings__alerts__variety-title {
    font-weight: 700;
    color: white;
}

.alert-settings__alerts-container {
    position: relative;

    padding: 0 10px 20px 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    width: 750px;
    height: max-content;

    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}
