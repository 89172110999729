.risk-factors__settings-container {
    display: flex;
    flex-direction: column;
}

.risk-advanced-settings {
    color: #3984bc;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    text-align: end;
}

.risk-factors__risks-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.risk-factors__risks {
    /* margin-top: 10px; */
    margin-right: auto;
    display: flex;
}

.cancel-button {
    background: #f3f7fb !important;
    box-shadow: 0px 4px 10px #c0d2e4;
    border: 1px solid rgb(211, 56, 56) !important;
    color: rgb(211, 56, 56) !important;
    border-radius: 11px !important;
    text-transform: capitalize !important;
    padding: 3px 8px !important;
    letter-spacing: initial !important;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 12px 0px;
    background-color: #50505073;
}

.risk-advanced-settings__buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.risk-factors__color-block {
    display: inline-block;
    margin-right: 7px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
}

.risk-factors__risk-name {
    margin-bottom: 6px;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 700;
}

.risk-factors__description {
    font-family: "Roboto";
    font-size: 12px;
}

@media screen and (max-width: 1100px) {
    .risk-factors__risks-container {
        padding: 0 10px;
        flex-direction: row;

        justify-content: center;
        /* justify-items: center; */
        place-items: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 15px 20px;
    }

    .risk-factors__settings-container {
        flex-basis: 100%;
    }

    .risk-factors__risks {
        margin-right: 0;
        padding: 5px;
        flex-basis: 29%;
    }

    .risk-factors__color-block {
        margin-bottom: -20px;
        width: 30px;
        height: 30px;
    }

    .risk-factors__description {
    }
}
