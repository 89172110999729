.color-picker {
    display: flex;
    flex: 1;

    align-items: center;
}

.color-picker__item {
    display: block;

    outline: none;
    background: none;
    border: none;

    width: 10px;
    height: 10px;
    margin-right: 2px;

    border-radius: 5px;

    cursor: pointer;

    transition: all 0.1s ease-out;
}

.color-picker__item:hover {
    width: 12px;
    height: 12px;

    border: 1px solid #505050;

    border-radius: 6px;
}

.color-picker__item:last-of-type {
    margin-right: initial;
}
