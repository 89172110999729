.satellite-model {
    height: 100%;
    /* Solve this issue with the width */
    width: 95%;
    box-sizing: border-box;
    overflow: hidden;
}

.satellite-model__container {
    display: grid;
    grid-template-columns: 67% 23%;
    gap: 2em;
    padding-top: 2em;
    padding-left: 2em;
    padding-bottom: 2em;
    height: 100%;
    width: 100%;
}

.satellite-model__graphs-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    height: 100%;
    width: 100%;
}

.satellite-model__graph-item {
}

.satellite-model__ndvi-graph {
}

.satellite-model__right-container__content {
    height: 100%;
    width: 100%;
}
