.gddgraph {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%);
    box-shadow: 0px 4px 10px #c0d2e4;
}

.gddgraph_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
}
