.label-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 9px;
}

.label-item__label,
.label-item__delete {
    outline: none;
    border: none;
    background: none;
}

.label-item__label {
    position: relative;

    display: flex;

    align-items: center;
    justify-content: flex-start;

    width: 95px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 5px;

    border-radius: 10px;

    overflow: hidden;
    white-space: nowrap;

    font-size: 10px;
    color: #505050;

    cursor: pointer;
}

.label-item__label::before {
    content: "Select";

    display: block;
    width: 0;

    font-weight: 700;

    opacity: 0;

    /* transition: all 0.2s ease-out; */
}

.label-item__label:hover {
    background: #e7e8eb;
    padding-left: 5px;
}

.label-item__label:hover .label-item__label__color-dot {
    display: none;
}

.label-item__label:hover::before {
    width: initial;
    padding-right: 3px;

    opacity: 1;
}

.label-item__label__color-dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;

    border-radius: 5px;
}

.label-item__label__name {
    max-width: 65px;
    overflow: hidden;
}

.label-item__delete {
    width: 20px;
    height: 20px;

    border-radius: 10px;
    padding: 2px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

/* This is going to change later */
.label-item__delete > div {
    width: 100%;
    height: 100%;
}

.label-item__delete:hover {
    background-color: #ddd;
}
