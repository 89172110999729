.file-input {
    width: 26px;
    height: 26px;
    border-radius: 13px;

    position: relative;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.file-input:hover {
    background-color: #ddd;
}

.file-input__file-icon-container {
    width: 26px;
    height: 26px;
}

.file-input__file-icon {
    width: 100% !important;
    height: 100% !important;
}

.file-input__upload-icon {
    width: 26px;
    height: 26px;
}

.file-input__input {
    display: block;
    width: 26px;
    height: 26px;
    background: #000;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    opacity: 0;
}

@media screen and (min-width: 2300px) {
    .file-input {
        width: 38px;
        height: 38px;
    }
}
