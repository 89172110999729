.popover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plain-popover {
    display: block !important;
}

.popover.full-width {
    width: 100%;
}

.popover:hover::after {
    content: attr(data-value);
    display: flex;

    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: calc(100% + 5px);
    z-index: 999;

    padding: 5px;

    min-width: 50px;

    border-radius: 10px;
    background: #333;

    font-size: 10px;
    text-align: center;

    color: #fff;
}

@media screen and (min-width: 1440px) {
    .popover:hover::after {
        font-size: 11px;
    }
}

@media screen and (min-width: 1920px) {
    .popover:hover::after {
        font-size: 12px;
    }
}

@media screen and (min-width: 2300px) {
    .popover:hover::after {
        min-width: 80px;
        font-size: 16px;
    }
}
